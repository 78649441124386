import React from "react"
import {Section} from "../Section"
import {Circle} from "../Circle"
import * as styles from "./Rollen.module.scss"
import {roles as section} from "../../data/Roles";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Rollen = ({language}: {language: Language}) => {
  const t = localizer(language)

  return (
    <Section id={toId(t(section.title))} title={t(section.title)} navigation avoidPageBreak language={language}>
      <div className={styles.container}>
        <h2>{t(section.roles[0].title)}</h2>
        <div className={styles.trainerCircle}>
          <div className={styles.circleContainer}>
            <Circle text={`${section.roles[0].durationInYears} ${t(section.years)}`}/>
          </div>
        </div>
        <h2 className={styles.consultingTitle}>{t(section.roles[1].title)}</h2>
        <div className={styles.consultingCircle}>
          <div className={styles.circleContainer}>
            <Circle text={`${section.roles[1].durationInYears} ${t(section.years)}`}/>
          </div>
        </div>
        <h2 className={styles.architectureTitle}>{t(section.roles[2].title)}</h2>
        <div className={styles.architectureCircle}>
          <div className={styles.circleContainer}>
            <Circle text={`${section.roles[2].durationInYears} ${t(section.years)}`}/>
          </div>
        </div>
        <h2 className={styles.developmentTitle}>{t(section.roles[3].title)}</h2>
        <div className={styles.developmentCircle}>
          <div className={styles.circleContainer}>
            <Circle text={`${section.roles[3].durationInYears} ${t(section.years)}`}/>
          </div>
        </div>
      </div>
    </Section>
  )
}
