import * as styles from "./Chip.module.scss"
import React, {CSSProperties, FunctionComponent} from "react"

interface ChipProperties {
  image: any
  alt: string
  isActive?: boolean
  onClick?: (topic: string) => void
  topic?: string
  customstyles?: CSSProperties
}

export const Chip:FunctionComponent<ChipProperties> = ({image, alt, isActive, onClick, topic, customstyles, children}) => {
  return (
    <div className={styles.chipContainer} style={customstyles}>
      <div className={`${styles.chip} ${onClick ? styles.clickable : ''} ${isActive ? styles.active : ''}`}
           onClick={() => { typeof onClick === "function" && onClick(topic || "undefined") }}
      >
        <img src={image} alt={alt} width="96" height="96"/>
        {children}
      </div>
    </div>
  )
}
