import React, {FunctionComponent, useEffect, useRef} from "react";

import * as d3 from "d3";
import {InformationBox} from "./InformationBox";
import {ITreeRepresentationNode} from "../model/TreeGraph";
import flextree from "./flextree";
import {Language} from "../model/LanguageBundle";
import {localizer} from "../i18n";

export type TreeGraphProbs = {
  rootNode: ITreeRepresentationNode
  configuration?: TreeGraphConfiguration
  language: Language
}

export type Depth = number
export type Width = number

export type TreeGraphConfiguration = {
  depthWidths?: Map<Depth, Width>
  treeStyles?: IStyle[]
}

interface IStyle {
  style: string,
  value: string
}

interface FlextreeNode {
  x: number
  y: number
  depth: number
  size: number[]
  parent: FlextreeNode
  children: FlextreeNode[]
  data: ITreeRepresentationNode
}

export const TreeGraph: FunctionComponent<TreeGraphProbs> =
  ({rootNode, configuration, language}) => {
    const treeStyles: IStyle[] = configuration?.treeStyles || [{style: "border", value: "1px solid white"}]
    const ref: any = useRef()
    const nodeSizeHeights = {
      oneLiner: 25,
      twoLiner: 45
    }

    const getDepthWidth = (depth: number) =>
      configuration?.depthWidths?.get(depth) || 250

    const getNodeSpace = (nodeA: FlextreeNode, nodeB: FlextreeNode) => {
      const space = nodeA.parent !== nodeB.parent ? 25 : 0
      return space + nodeA.data.properties.extraSpace
    }

    const getNodeSize = (node: FlextreeNode) =>
      [
        node.data.data.aliases ? nodeSizeHeights.twoLiner : nodeSizeHeights.oneLiner,
        getDepthWidth(node.depth)
      ]

    useEffect(() => {
      const layout: any = flextree();
      layout.spacing(getNodeSpace)
      layout.nodeSize(getNodeSize)
      const tree = layout.hierarchy(rootNode);
      layout(tree)

      // we'll need to adapt the height of the SVG to accommodate all of the nodes
      //  ...but how do we find out the right height?
      //  we can't just center it because there is no guarantee the root is in the middle in a Tidy Tree
      //  instead, we locate the top and bottom nodes (which are left and right in d3 tree land)
      const treeSize = {
        topNode: tree,
        bottomNode: tree,
        treeDepth: 0,
        getDepthArray: () => Array.from(Array(treeSize.treeDepth + 1)).map((_, idx) => idx)
      }

      tree.eachAfter((node: FlextreeNode) => {
        if (node.x < treeSize.topNode.x) {
          treeSize.topNode = node
        }
        if (node.x > treeSize.bottomNode.x) {
          treeSize.bottomNode = node
        }
        if (node.depth > treeSize.treeDepth) {
          treeSize.treeDepth = node.depth
        }
      });

      const height: number = treeSize.bottomNode.x - treeSize.topNode.x + 55
      const width = treeSize
        .getDepthArray()
        .map(idx => getDepthWidth(idx))
        .reduce((sum, cur) => sum + cur)
      const maxWidthDefault = 1200
      const maxWidth = width < maxWidthDefault ? width : maxWidthDefault

      const divElement = d3.select(ref.current)
      divElement.style("max-width", `${maxWidth}px`)

      const svgElement = divElement
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .classed("svg-content", true)
        .style(treeStyles[0].style, treeStyles[0].value)
      const g = svgElement.append("g")

      // adds the links between the nodes
      g
        .selectAll(".link")
        .data((tree.descendants() as Array<FlextreeNode>).slice(1))
        .enter()
        .append("path")
        .attr("style", (node) => `fill: none; stroke: ${node.data.properties.color}; stroke-width: 2px;`)
        .attr("d", node => {
          if (!node.parent) return ""
          const startPoint = {
            x: node.x,
            y: node.children ? node.y : node.y
          }
          const endPoint = {
            x: node.parent.x,
            y: node.parent.y
          }
          const startControlPoint = {
            x: node.x,
            y: (startPoint.y + node.parent.y) / 2
          }
          const endControlPoint = {
            x: node.parent.x,
            y: (startPoint.y + node.parent.y) / 2
          }
          return `M${startPoint.y},${startPoint.x}C${startControlPoint.y},${startControlPoint.x} ${endControlPoint.y}, ${endControlPoint.x} ${endPoint.y},${endPoint.x}`
        });

      // add each node as a group
      var node = g
        .selectAll(".node")
        .data(tree.descendants() as Array<FlextreeNode>)
        .enter()
        .append("g")
        .attr("class", node => "node" + (node.children ? " node--internal" : " node--leaf"))
        .attr("transform", node => {
          const y = node.children ? node.y : node.y
          return `translate(${y},${node.x})`
        });

      // adds the circle to the node
      node.append("circle")
        .attr("r", node => node.data.properties.radius)
        .style("stroke", "#4a5f90")
        .style("stroke-width", "1px")
        .style("fill", node => node.data.properties.color)

      // adds the text to the node
      const textNode = node.append("text")
        .attr("style", "font-family: Muli, Helvetica, Arial, sans-serif; font-weight: 400; font-size: 0.9rem")
        .attr("dy", ".35em")
        .attr("x", node => {
          const margin = 5
          const x = (node.data.properties.radius || 13) + margin
          return node.children ? -x : x;
        })
        .style("text-anchor", node => node.children ? "end" : "start")

      textNode
        .append("tspan")
        .text(node => node.parent ? node.data.data.name : "") // Add labels for all nodes except the root.
      textNode
        .append("tspan")
        .attr("style", "font-family: Muli, Helvetica, Arial, sans-serif; font-weight: 100; font-size: 0.9rem")
        .attr("x", node => {
          const margin = 5
          const x = (node.data.properties.radius || 13) + margin
          return node.children ? -x : x;
        })
        .attr("y", "1.7em")
        .text(node => node.parent ? node.data.data.aliases?.join(", ") || "" : "") // Add labels for all nodes except the root.

      g.attr("transform", "translate(" + (25) + "," + (treeSize.topNode.x * -1 + 25) + ")")
    }, [])

    return (
      <>
        <noscript>
          <NoJavascriptNoGraphInfoBox language={language}/>
        </noscript>
        <div style={{maxWidth: 850}} ref={ref}/>
      </>
    )
  }

const NoJavascriptNoGraphInfoBox = ({language}: { language: Language }) => {
  const t = localizer(language)
  const info = {
    header: {
      de: "Der Graph kann mit Ihren aktuellen Browsereinstellungen nicht angezeigt werden.",
      en: "The graph cannot be displayed with your current browser settings."
    },
    text: {
      de: "Aktivieren Sie JavaScript falls sie diesen Graphen anzeigen möchten.",
      en: "Enable JavaScript if you want to view this graph."
    }
  }
  return (
    <InformationBox>
      <h2>{t(info.header)}</h2>
      <div>
        <p>{t(info.text)}</p>
      </div>
    </InformationBox>
  )
}
