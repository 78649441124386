import {PersonType} from "../../model/PersonType";
import {CvHeader} from "./Header";
import {LayoutContentArea} from "../LayoutContentArea";
import {TableOfContents} from "../TableOfContents";
import {tableOfContents} from "../../data/TableOfContents";
import {BadExperienceDetect} from "../BadExperienceDetect";
import {Introduction} from "./Introduction";
import {TableOfContentsFlat} from "./TableOfContentsFlat";
import {VersionInformation} from "./VersionInformation";
import {Abstract} from "./Abstract";
import {Branchenerfahrung} from "./Branchenerfahrung";
import {Clients} from "./Clients";
import {Rollen} from "./Rollen";
import {Personal} from "./Personal";
import {Preferences} from "./Preferences";
import {ProfessionalDevelopment} from "./ProfessionalDevelopment";
import {SoftSkills} from "./SoftSkills";
import {KnowHow} from "./KnowHow";
import {projects} from "../../data/Projects";
import {ContactDetails} from "./ContactDetails";
import {Layout} from "../Layout";
import React from "react";
import {Language} from "../../model/LanguageBundle";
import {siteMetaData} from "../../siteMetaData";
import {localizer} from "../../i18n";
import {NoJavascriptInfobox} from "../NoJavascriptInfobox";
import {FilterController, ProjectsContainer} from "./ProjectsContainer";

export default ({personType, language, href}: { personType: PersonType, language: Language, href: string }) => {
  const t = localizer(language)

  let filterController: FilterController
  const setFilterController = (controller: FilterController) => {
    filterController = controller
  }
  const resetFilterWhenLinkWasClicked = () => {
    filterController.resetFilter()
  }

  return (
    <Layout
      title={PersonType.FirstPersonSingular === personType ? t(siteMetaData.title) : "Digitales CV in 3. Person Singular"}
      description={t(siteMetaData.description)}
      language={language}
      href={href}
      imageUrl={t({de: `${siteMetaData.siteUrl}/open-graph.jpg`, en: `${siteMetaData.siteUrl}/open-graph-en.jpg`})}
    >
      <CvHeader language={language}/>

      <LayoutContentArea aside={<TableOfContents toc={tableOfContents(language)} linkClicked={resetFilterWhenLinkWasClicked}/>}>
        <BadExperienceDetect language={language}/>
        <noscript>
          <aside data-nosnippet>
            <NoJavascriptInfobox language={language}/>
          </aside>
        </noscript>
        <Introduction personType={personType} language={language}/>
        <TableOfContentsFlat toc={tableOfContents(language)} language={language}/>
        <VersionInformation personType={personType} language={language}/>
        <Abstract personType={personType} language={language}/>
        <Branchenerfahrung language={language}/>
        <Clients language={language}/>
        <Rollen language={language}/>
        <Personal language={language}/>
        <Preferences language={language}/>
        <ProfessionalDevelopment personType={personType} language={language}/>
        <SoftSkills personType={personType} language={language}/>
        <KnowHow personType={personType} language={language}/>
        <ProjectsContainer projects={projects} personType={personType} language={language} filterController={setFilterController}/>
        <ContactDetails language={language}/>
      </LayoutContentArea>
    </Layout>
  )
}
