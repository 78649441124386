import {ILanguageBundle, Language} from "./model/LanguageBundle";
import {ITableOfContentsBranch} from "./components/TableOfContents";
import {localizer, toId} from "./i18n";

export default (language: Language) => {
  const t = localizer(language)

  const createNode = (headerText: string | ILanguageBundle, nodes: ITableOfContentsBranch[] = []): ITableOfContentsBranch => ({
    id: `toc-item-${toId(t(headerText))}`,
    href: `#${toId(t(headerText))}`,
    text: t(headerText),
    nodes: nodes
  })

  return {
    createNode
  }
}
