import {siteMetaData} from "../siteMetaData";

export const introduction = {
  header: {
    de: "Einleitung",
    en: "Introduction"
  },
  content: {
    de: `
Mein Name ist [[name]]Bastian Nolte[[/name]]. 
Ich verfüge über zirka ${siteMetaData.overallExperienceInYears} Jahre Berufserfahrung im IT-Bereich, sowohl als [[role]]Consultant[[/role]], 
[[role]]Trainer/Moderator[[/role]], [[role]]IT-Architekt[[/role]] und [[role]]Software-Entwickler[[/role]], als auch in 
Projektleitungsfunktion mit und ohne Personalverantwortung.

Ich sehe mich eher als [[role]]Generalist[[/role]] und Unterstützer, denn als Spezialist. Durch mein breites Wissen, kann ich dabei helfen, effiziente und zielgerichtete [[angebot]]Lösungen[[/angebot]]
auch für komplexe Problemstellungen zu finden und umzusetzen.

Gerne unterstütze ich Sie bei Bedarf dabei Veränderungen und [[angebot]]Innovationsprozesse[[/angebot]] anzustossen und erfolgreich zu etablieren, sowie Teams mit 
einer passenden Mischung aus Spezialisten und Generalisten aufzustellen und [[angebot]]weiterzubilden[[/angebot]].

Zurzeit unterstütze ich meine Kunden hauptsächlich in den Themenbereichen Consulting, Software-(entwicklung) und Schulung. Gerne unterstütze ich Sie dabei, sich in Richtung [[angebot]]Azure Cloud[[/angebot]] und [[angebot]]DevOps[[/angebot]]-Teams auszurichten.
`,
    en: `
My name is [[name]]Bastian Nolte[[/name]]. 
I have about ${siteMetaData.overallExperienceInYears} years of professional experience in the IT field, both as a [[role]]consultant[[/role]], [[role]]trainer/facilitator[[/role]], [[role]]IT architect[[/role]] and [[role]]software developer[[/role]], as well as in project management roles with and without personnel responsibilities.

I see myself more as a [[role]]generalist[[/role]] and supporter than a specialist. My broad knowledge will allow me to help you find and implement efficient [[angebot]]solutions[[/angebot]] even for complex problems.

I am looking forward to supporting you in initiating and successfully establishing changes and [[angebot]]innovation processes[[/angebot]]. 

Currently, I support my clients primarily in consulting, software (development) and [[angebot]]training[[/angebot]]. I am happy to help your Organisation in aligning towards [[angebot]]Azure Cloud[[/angebot]] and [[angebot]]DevOps[[/angebot]] teams.
    `
  }
}
