import {IPreferences} from "../model/Preferences";
import {getTechnologieName, getTechnologieNames} from "../TechnologyAggregate";

export const preferences: IPreferences = {
  header: {
    de: "Präferenzen",
    en: "Preferences"
  },
  subHeader: {
    de: "(immer offen für neue Technologien)",
    en: "(always open to new technologies)"
  },
  preferences: [
    {
      title: {
        de: "Aufgaben / Rollen",
        en: "Responsibilities / Roles"
      },
      text: {
        de: "IT Business Beratung, Workshops, Full Stack Cloud Entwicklung und Architektur in Azure- und Innovationsthemen",
        en: "IT business consulting, workshops, full stack cloud development and architecture in Azure and innovation topics"
      }
    },
    {
      title: {
        de: "Programmiersprachen",
        en: "Programming languages"
      },
      text: {
        de: `Polyglot (nach Anforderung), z.B. ${getTechnologieNames([
          "4c437fe3-9160-435d-9f03-dc5153bf47f2", // F#
          "589cbeac-a61f-48e8-a1fd-a097678447be", // TypeScript
        ])}`,
        en: `Polyglot (according to requirements), e.g. ${getTechnologieNames([
          "4c437fe3-9160-435d-9f03-dc5153bf47f2", // F#
          "589cbeac-a61f-48e8-a1fd-a097678447be", // TypeScript
        ])}`
      }
    },
    {
      title: {
        de: "Plattformen",
        en: "Platforms"
      },
      text: {
        de: `${getTechnologieNames([
          "4747773f-dfa3-4c26-a303-05d304b8d4c0" // Azure
        ])}`,
        en: `${getTechnologieNames([
          "4747773f-dfa3-4c26-a303-05d304b8d4c0" // Azure
        ])}`
      }
    },
    {
      title: {
        de: "Technologien / Frameworks",
        en: "Technologies / Frameworks"
      },
      text: {
        de: `${getTechnologieNames([
          "445df01b-9af2-483c-89b6-dd03a3c62d55", // .Net 5 (ehemals .Net Core)
          "d628d915-6809-4fd5-9deb-c14fc4bba636", // Spring Boot
          "42c4e807-8a34-488a-b9ca-2ff52c67b28a" // Microprofile
        ])} mit ${getTechnologieName("ed2de5ce-a60d-4d99-8ad1-02a75985b537")}`, // Quarkus
        en: `${getTechnologieNames([
          "445df01b-9af2-483c-89b6-dd03a3c62d55", // .Net 5 (ehemals .Net Core)
          "d628d915-6809-4fd5-9deb-c14fc4bba636", // Spring Boot
          "42c4e807-8a34-488a-b9ca-2ff52c67b28a" // Microprofile
        ])} with ${getTechnologieName("ed2de5ce-a60d-4d99-8ad1-02a75985b537")}`, // Quarkus
      }
    },
    {
      title: {
        de: "Web-Anwendungen",
        en: "Web applications"
      },
      text: {
        de: `Nach Anforderung, z.B. ${getTechnologieNames([
          "310d4ac3-4b55-4949-a8d2-b2586d9f0551", // Vue.js
          "424dd38a-c95e-403e-ba98-1f31651adff6", // React
          "bc611a2f-d07a-4af0-97b2-8312749ea847", // Angular
        ])}`,
        en: `According to requirements, e.g. ${getTechnologieNames([
          "310d4ac3-4b55-4949-a8d2-b2586d9f0551", // Vue.js
          "424dd38a-c95e-403e-ba98-1f31651adff6", // React
          "bc611a2f-d07a-4af0-97b2-8312749ea847", // Angular
        ])}`
      }
    },
    {
      title: {
        de: "Datenbanken",
        en: "Databases"
      },
      text: {
        de: `Nach Anforderung, z.B. ${getTechnologieNames([
          "41f0309a-242b-4ee2-90df-bd90d3b11713", // Azure Cosmos DB
          "13e7e3e8-86cf-454b-9777-fe7250103773", // Neo4J
        ])}`,
        en: `According to requirements, e.g. ${getTechnologieNames([
          "41f0309a-242b-4ee2-90df-bd90d3b11713", // Azure Cosmos DB
          "13e7e3e8-86cf-454b-9777-fe7250103773", // Neo4J
        ])}`
      }
    }
  ]
}
