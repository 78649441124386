import React, {FunctionComponent, useEffect} from "react";
import * as styles from "./TableOfContents.module.scss"

export interface TableOfContentsProps {
  toc: ITableOfContentsBranch[]
  rootContext?: boolean
  linkClicked?: () => void
}

export enum ExistenceState {
  NOT_EXISTS,
  EXISTS
}

export const TableOfContents: FunctionComponent<TableOfContentsProps> = ({toc, linkClicked}) => {
  useEffect(() => {
    const sections = Array
      .prototype
      .slice
      .call(document.querySelectorAll('.section-anchor'), 0)
      .reverse();

    const headerHeight = 56;

    let currentSectionInViewport: Element | undefined;

    const selectMenuItemOnScroll = debounce(() => {
      function modifyTreePathRecursively(nodeElement: HTMLLIElement, elementModifier: (d: Element) => void) {
        elementModifier(nodeElement)
        const parent = getParentTocAnchorIfExists(nodeElement)
        if (parent.state === ExistenceState.EXISTS) {
          modifyTreePathRecursively(parent.element!, elementModifier)
        }
      }

      function getParentTocAnchorIfExists(element: Element) {
        const parentElement = element.parentElement?.parentElement;
        if (parentElement && parentElement.nodeName === 'LI') {
          return {
            state: ExistenceState.EXISTS,
            element: parentElement as HTMLLIElement
          }
        }
        return {
          state: ExistenceState.NOT_EXISTS
        }
      }

      const firstSectionInViewport = sections.find((currentSection) => {
        return !currentSection.classList.contains("hidden") && currentSection.getBoundingClientRect().y - headerHeight <= 30
      })

      if (currentSectionInViewport !== firstSectionInViewport) {
        if (currentSectionInViewport) {
          const tocItemId = "toc-item-" + currentSectionInViewport.id
          const tocElement = document.getElementById(tocItemId) as HTMLLIElement
          if (tocElement) {
            modifyTreePathRecursively(tocElement, element => element.classList.remove(styles.active))
          }
        }

        currentSectionInViewport = firstSectionInViewport
        if (firstSectionInViewport) {
          const tocItemId = "toc-item-" + firstSectionInViewport.id
          const tocElement = document.getElementById(tocItemId) as HTMLLIElement
          if (tocElement) {
            modifyTreePathRecursively(tocElement, element => element.classList.add(styles.active))
          }
        }
      }
    }, 10, true);
    document.addEventListener('scroll', selectMenuItemOnScroll, {passive: true});
    selectMenuItemOnScroll()
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', selectMenuItemOnScroll);
    };
  }, []); // The [] indicates that we will run this code block only once.

  function debounce(callback: any, wait: number, immediate = false) {
    // eslint-disable-next-line no-undef
    let timeout: NodeJS.Timeout

    return function() {
      const callNow = immediate && !timeout
      // @ts-ignore
      const next = () => callback.apply(this, arguments)

      clearTimeout(timeout)
      timeout = setTimeout(next, wait)

      if (callNow) {
        next()
      }
    }
  }

  return (
    <div className={styles.toc}>
      <TableOfContentsBranch toc={toc} rootContext linkClicked={linkClicked}/>
    </div>
  )
}

export interface ITableOfContentsBranch {
  readonly id: string
  readonly href: string
  readonly text: string
  readonly type?: string
  readonly nodes: ITableOfContentsBranch[]
}

const TableOfContentsBranch: FunctionComponent<TableOfContentsProps> = ({rootContext, toc, linkClicked}) => {
  return (
    <ul>
      {
        toc.map((element: ITableOfContentsBranch, idx) => {
          return (
            <li key={idx} id={element.id} className={rootContext ? styles.mainSection : undefined}>
              <div className={styles.marker}>&nbsp;</div>
              <a href={element.href} type={element.type || "text/html"} onClick={linkClicked}>{element.text}</a>
              {element.nodes && element.nodes.length > 0 && <TableOfContentsBranch toc={element.nodes} linkClicked={linkClicked}/>}
            </li>
          )
        })
      }
    </ul>
  )
}
