import {InformationBox} from "./InformationBox";
import React from "react";
import {t} from "../i18n";
import {ILanguageBundle, Language} from "../model/LanguageBundle";

export const NoJavascriptInfobox = ({language}: { language: Language }) => {
  const infoText: ILanguageBundle = {
    de: `
      <h1>
        Diese Webseite kann auf Grund Ihrer Browsereinstellungen nicht korrekt ausgeführt werden.
      </h1>
      <div>
        <p>Aktivieren Sie JavaScript, um alle Funktionen dieser Webseite nutzen zu können.</p>
        <p>Folgende Funktionen der Webseite stehen wegen deaktiviertem Javascript nicht zur Verfügung:</p>
        <ul>
          <li>Das Menü in der Navigationszeile oben rechts im Browser.</li>
          <li>Der Soft-Skill-Graph (die Listen stehen allerdings zur Verfügung).</li>
          <li>Die Know-how-Graphen (die Listen stehen allerdings zu Verfügung).</li>
          <li>Die Filterung von Projektreferenzen ist deaktiviert.</li>
        </ul>
      </div>
    `,
    en: `
      <h1>
        This website may not run correctly due to your browser settings.
      </h1>
      <div>
        <p>Enable JavaScript if you want to use all features of this website.</p>
        <p>The following website features are not available due to deactivated Javascript:</p>
        <ul>
          <li>The menu in the navigation bar at the top right of the browser.</li>
          <li>The soft skill graph (the lists are available though).</li>
          <li>The know-how graphs (the lists are, however, available).</li>
          <li>Filtering of project references is disabled.</li>
        </ul>
      </div>
    `
  }

  return (
    <InformationBox styles={{borderColor: 'red'}}>
      <div dangerouslySetInnerHTML={{__html: t(infoText, language)}}/>
    </InformationBox>
  )
}
