import {Section} from "../Section";
import React from "react";
import {domainDrivenDesign} from "../../data/DomainDrivenDesign";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const DddKnowHow = ({language}: { language: Language }) => {
  const ddd = {
    tacticalDesign: {
      de: "Taktisches Design",
      en: "Tactical design"
    },
    stategicDesign: {
      de: "Strategisches Design",
      en: "Strategic design"
    },
    accessWith: {
      de: "greife zu mit…",
      en: "access with…"
    },
    root: {
      de: ["fungiere als", "Wurzel für…"],
      en: ["act as", "root for…"]
    },
    encapsulate: {
      de: "kapseln",
      en: "encapsulates"
    },
    encapsulateCreation: {
      de: ["kapsle", "Erzeugung mit…"],
      en: ["encapsulate", "creation with…"]
    },
    encapsulateWith: {
      de: "kapsle ein mit…",
      en: "encapsulate with…"
    },
    stateChange: {
      de: ["drücke Zustands-", "änderung aus durch…"],
      en: ["push change", "of state by…"]
    },
    expressModel: {
      de: "präzisiere Model mit…",
      en: "express model with..."
    },
    expressIdentity: {
      de: "präzisiere Identität mit…",
      en: "express identity with…"
    },
    expressState: {
      de: "präzisiere Zustand & Berechnungen mit…",
      en: "express state & computation with…"
    },
    expressChange: {
      de: "präzisieren Änderungen mit…",
      en: "express change with…"
    },
    isolateDomain: {
      de: "isoliere Domäne mit…",
      en: "isolate domain with..."
    },
    modelStructure: {
      de: "Model gibt Struktur",
      en: "model gives structure to…"
    },
    defineModelWithin: {
      de: ["definiere das", "Modell innerhalb des…"],
      en: ["define the model", "within the…"]
    },
    namesEnter: {
      de: "die Namen führen zu…",
      en: "names enter…"
    },
    richModel: {
      de: ["reichhaltiges Modell", "kultivieren mit…"],
      en: ["cultivate rich", "model with…"]
    },
    overviewRelationships: {
      de: ["Überblick über Beziehungen", "verschaffen mit…"],
      en: ["assess/overview", "relationships with"]
    },
    unifiedModel: {
      de: ["Modell eindeutig", "halten mit…"],
      en: ["keep model", "unified by"]
    },
    avoidOverinvesting: {
      de: ["zu grosse", "Investitionen vermeiden"],
      en: ["aviod", "overinvesting in"]
    },
    workAutonomous: {
      de: ["arbeiten in einem", "autonomen", "klar umrissenen…"],
      en: ["work in", "autonomous", "clean"]
    },
    independentContexts: {
      de: ["zusammenhängende", "Kontexte überlappen mit"],
      en: ["connected contexts", "overlap with"]
    },
    alliedContexts: {
      de: ["zusammenhängende", "Kontexte verknüpfen als…"],
      en: ["relate allied", "contexts as…"]
    },
    minimizeTranslations: {
      de: ["Übersetzungen", "minimieren als…"],
      en: ["minimize", "translation"]
    },
    supportMultipleClients: {
      de: ["unterstützt mehrere", "clients durch…"],
      en: ["support multiple", "clients through…"]
    },
    formalize: {
      de: "formalisiert als…",
      en: "formalized as…"
    },
    freeTeams: {
      de: ["macht die Teams", "unabhängig"],
      en: ["free Teams", "to go"]
    },
    insulate: {
      de: ["einseitig übersetzen", "und isolieren mit…"],
      en: ["translate & insulate", "unilaterally with…"]
    },
    segregateMesses: {
      de: ["trennt das", "konzeptuelle", "Durcheinander", "des…"],
      en: ["segregate the", "conceptual", "messes of…"]
    }
  }
  const t = localizer(language)
  return (
    <Section id={toId(t(domainDrivenDesign.shortName))}
             title={t(domainDrivenDesign.name)}
             subtitle={t(domainDrivenDesign.description)}
             navigation
             avoidPageBreak
             language={language}>
      <p>{t(domainDrivenDesign.note)}</p>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="15.382251 -9.81736 1671.6124 1050.5735" width="100%" height="100%">
        <defs>
          <marker
            orient="auto"
            overflow="visible"
            markerUnits="strokeWidth"
            id="Arrow_Marker_1"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            viewBox="-1 -4 8 8"
            markerWidth="8"
            markerHeight="8"
            color="#8ebd5b">
            <g>
              <path d="M 5.6 0 L 0 -2.1 L 0 2.1 Z" fill="none" stroke="currentColor" strokeWidth="1"/>
            </g>
          </marker>
          <marker
            orient="auto"
            overflow="visible"
            markerUnits="strokeWidth"
            id="Arrow_Marker_2"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            viewBox="-1 -4 8 8"
            markerWidth="8"
            markerHeight="8"
            color="#69a3b4">
            <g>
              <path d="M 5.6 0 L 0 -2.1 L 0 2.1 Z" fill="none" stroke="currentColor" strokeWidth="1"/>
            </g>
          </marker>
          <marker
            orient="auto"
            overflow="visible"
            markerUnits="strokeWidth"
            id="Arrow_Marker_3"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            viewBox="-7 -4 8 8"
            markerWidth="8"
            markerHeight="8"
            color="#8ebd5b">
            <g>
              <path d="M -5.6 0 L 0 2.1 L 0 -2.1 Z" fill="none" stroke="currentColor" strokeWidth="1"/>
            </g>
          </marker>
          <marker
            orient="auto"
            overflow="visible"
            markerUnits="strokeWidth"
            id="Arrow_Marker_4"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            viewBox="-7 -4 8 8"
            markerWidth="8"
            markerHeight="8"
            color="#69a3b4">
            <g>
              <path d="M -5.6 0 L 0 2.1 L 0 -2.1 Z" fill="none" stroke="currentColor" strokeWidth="1"/>
            </g>
          </marker>
        </defs>
        <g id="Canvas_1" fill="none" strokeOpacity="1" fillOpacity="1" stroke="none" strokeDasharray="none">
          <g id="Canvas_1_Layer_1">
            <g id="Line_207">
              <path
                d="M 1232 948 C 1279 980 1357 975 1411 950 M 1232 948 C 1280 980 1358 974 1411 949"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="g_118">
              <text transform="translate(533 35)" fill="#69a4b4">
                <tspan fontSize="28" fontWeight="600" fill="#69a4b4" x="0" y="29">{t(ddd.tacticalDesign)}</tspan>
              </text>
            </g>
            <g id="g_122">
              <text transform="translate(31 277)" fill="#8ebd5a">
                <tspan fontSize="28" fontWeight="600" fill="#8ebd5a" x="0" y="29">{t(ddd.stategicDesign)}</tspan>
              </text>
            </g>
            <g id="Line_147">
              <path
                d="M 1225 201 C 1268 168 1303 141 1328 115 M 1225 201 C 1269 167 1304 140 1329 114"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_146">
              <path
                d="M 1187 283 C 1231 345 1328 335 1374 394 M 1187 283 C 1232 344 1328 334 1375 394"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_145">
              <path
                d="M 1256 223 C 1320 220 1364 214 1431 228 M 1256 223 C 1321 219 1365 213 1432 227"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_144">
              <path
                d="M 814 345 C 823 360 833 378 840 398 M 814 345 C 823 359 834 377 841 398"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_143">
              <path
                d="M 842 256 C 877 213 887 179 896 145 M 842 256 C 878 213 888 178 897 145"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_140">
              <path
                d="M 882 271 C 931 255 984 243 1046 243 M 882 271 C 931 254 985 242 1047 243"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_139">
              <path
                d="M 890 320 C 929 336 967 353 1000 371 M 890 320 C 929 335 968 352 1001 370"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_138">
              <path
                d="M 764 256 C 727 234 677 214 642 203 M 764 256 C 727 233 678 213 643 202"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_137">
              <path
                d="M 543 478 C 573 388 658 437 764 341 M 543 478 C 574 387 659 436 765 340"
                markerStart="url(#Arrow_Marker_3)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_136">
              <path
                d="M 168 595 C 182 539 216 489 283 447 M 168 595 C 182 538 217 488 284 446"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_135">
              <path
                d="M 144 720 C 144 751 156 780 175 816 M 144 720 C 145 751 157 779 176 815"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_134">
              <path
                d="M 484 639 C 431 706 342 714 266 696 M 484 639 C 431 705 342 713 267 695"
                markerStart="url(#Arrow_Marker_3)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_131">
              <path
                d="M 532 624 C 527 673 523 725 501 764 M 532 624 C 527 672 524 724 501 763"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_130">
              <path
                d="M 819 757 C 837 655 888 628 1006 580 M 819 757 C 838 654 889 627 1006 580"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_129">
              <path
                d="M 867 763 C 929 712 949 697 1061 663 M 867 763 C 930 711 950 697 1062 663"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_128">
              <path
                d="M 909 779 C 997 765 1058 757 1132 761 M 909 779 C 997 764 1059 757 1133 761"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_126">
              <path
                d="M 891 849 C 935 872 960 897 986 935 M 891 849 C 936 871 961 897 986 934"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_125">
              <path
                d="M 804 860 L 790 948 M 805 860 L 791 947"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_124">
              <path
                d="M 721 844 C 660 863 606 899 570 937 M 721 844 C 660 862 607 898 570 936"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_123">
              <path
                d="M 608 613 C 658 649 713 684 756 744 M 608 613 C 659 648 713 684 757 744"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="foo" fontSize="22" fontWeight="600">
              <g id="g_55">
                <title>Bounded Context</title>
                <path
                  d="M 623 519 C 668 543 675 581 623 604 C 577 630 495 629 440 604 C 385 585 386 542 440 519 C 493 496 567 496 623 519 M 623 519 C 669 542 676 580 623 604 C 578 629 496 628 440 604 C 385 585 387 541 440 519 C 494 495 567 495 623 519"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(433 544)" fill="#8dbd5a">
                  <tspan x="14" y="23">Bounded Context</tspan>
                </text>
              </g>
              <g id="g_2">
                <title>Context Map</title>
                <path
                  d="M 895 774 C 945 790 942 828 895 845 C 849 861 776 865 728 845 C 684 830 680 797 728 774 C 775 759 849 755 895 774 M 895 774 C 946 790 942 828 895 844 C 850 861 777 865 729 844 C 684 830 681 796 729 774 C 775 758 850 755 895 774"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(722 792)" fill="#8dbd5a">
                  <tspan fill="#8dbd5a" x="27" y="23">Context Map</tspan>
                </text>
              </g>
              <g id="g_68">
                <title>Continuous Integration</title>
                <path
                  d="M 530 797 C 566 815 565 842 530 859 C 495 876 432 877 396 859 C 359 846 363 812 396 797 C 436 783 490 779 530 797 M 530 797 C 566 815 566 841 530 858 C 495 875 432 877 396 858 C 359 845 364 811 396 796 C 437 782 491 778 530 796"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(392 793)" fill="#8dbd5a">
                  <tspan x="14" y="23">Continuous</tspan>
                  <tspan x="15" y="58">Integration</tspan>
                </text>
              </g>
              <g id="g_57">
                <title>Core Domain</title>
                <path
                  d="M 253 615 C 307 643 300 675 253 700 C 207 719 126 727 70 700 C 24 673 15 643 70 615 C 121 587 198 589 253 615 M 253 615 C 308 642 301 675 254 699 C 208 718 126 726 71 699 C 24 672 15 642 71 614 C 122 586 199 588 254 614"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(63 622)" fill="#8dbd5a">
                  <tspan fill="#8dbd5a" x="75" y="23">Core</tspan>
                  <tspan fill="#8dbd5a" x="60" y="58">Domain</tspan>
                </text>
              </g>
              <g id="g_74">
                <title>Model-Driven Design</title>
                <path
                  d="M 870 267 C 908 288 909 311 870 330 C 835 349 772 346 736 330 C 701 314 699 285 736 267 C 772 252 834 251 870 267 M 870 267 C 908 287 909 310 871 329 C 835 348 773 345 737 329 C 702 314 699 285 737 267 C 772 252 834 251 871 267"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(732 264)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="4" y="23">Model-Driven</tspan>
                  <tspan fill="#69a3b4" x="37" y="58">Design</tspan>
                </text>
              </g>
              <g id="g_80">
                <title>Value Objects</title>
                <path
                  d="M 1154 383 C 1194 397 1189 425 1154 446 C 1118 459 1056 460 1021 446 C 980 428 987 404 1021 383 C 1059 367 1121 370 1154 383 M 1154 383 C 1195 397 1189 425 1155 445 C 1119 459 1057 459 1021 445 C 980 427 987 403 1021 383 C 1060 366 1121 369 1155 383"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(1017 397)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="3" y="23">Value Objects</tspan>
                </text>
              </g>
              <g id="g_75">
                <title>Entities</title>
                <path
                  d="M 1231 206 C 1268 224 1268 254 1231 269 C 1196 290 1137 290 1097 269 C 1061 250 1059 226 1097 206 C 1136 189 1191 185 1231 206 M 1231 206 C 1268 224 1268 253 1232 268 C 1196 289 1138 289 1098 268 C 1062 249 1060 225 1098 206 C 1137 189 1192 185 1232 206"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(1093 220)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="33" y="23">Entities</tspan>
                </text>
              </g>
              <g id="g_92">
                <title>Aggregates</title>
                <path
                  d="M 1640 223 C 1683 245 1681 281 1640 300 C 1593 323 1517 323 1473 300 C 1427 283 1429 245 1473 223 C 1519 198 1595 201 1640 223 M 1640 223 C 1684 244 1681 280 1640 300 C 1594 323 1517 322 1474 300 C 1428 282 1430 244 1474 222 C 1520 198 1595 200 1640 222"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(1467 244)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="33" y="23">Aggregates</tspan>
                </text>
              </g>
              <g id="g_90">
                <title>Factories</title>
                <path
                  d="M 1472 426 C 1510 441 1510 474 1472 489 C 1436 504 1374 504 1339 489 C 1304 473 1305 440 1339 426 C 1378 410 1435 411 1472 426 M 1472 426 C 1510 440 1510 473 1473 488 C 1436 503 1374 504 1339 488 C 1305 472 1306 440 1339 426 C 1378 410 1436 410 1473 426"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(1335 440)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="25" y="23">Factories</tspan>
                </text>
              </g>
              <g id="g_91">
                <title>Repositories</title>
                <path
                  d="M 1450 26 C 1486 46 1485 69 1450 88 C 1413 105 1355 109 1316 88 C 1280 69 1282 42 1316 26 C 1350 8 1409 5 1450 26 M 1450 26 C 1487 46 1486 68 1450 88 C 1413 104 1356 109 1317 88 C 1281 69 1282 41 1317 25 C 1351 8 1410 5 1450 25"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(1312 40)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="9" y="23">Repositories</tspan>
                </text>
              </g>
              <g id="g_81">
                <title>Domain Events</title>
                <path
                  d="M 1008 29 C 1056 54 1058 86 1008 107 C 963 126 890 131 841 107 C 799 81 798 47 841 29 C 883 7 961 6 1008 29 M 1008 29 C 1056 53 1058 86 1008 106 C 963 126 891 130 842 106 C 799 81 798 47 842 29 C 884 6 962 5 1008 29"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(835 51)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="16" y="23">Domain Events</tspan>
                </text>
              </g>
              <g id="g_82">
                <title>Services</title>
                <path
                  d="M 914 435 C 952 452 952 476 914 497 C 878 514 816 513 780 497 C 745 481 745 451 780 435 C 817 415 873 419 914 435 M 914 435 C 952 451 953 475 915 496 C 879 513 816 512 781 496 C 746 481 745 450 781 434 C 818 414 874 418 915 434"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(776 448)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="29" y="23">Services</tspan>
                </text>
              </g>
              <g id="g_83">
                <title>Layered Architecture</title>
                <path
                  d="M 639 124 C 675 139 675 169 639 186 C 603 205 545 207 505 186 C 469 167 469 142 505 124 C 546 111 603 103 639 124 M 639 124 C 676 138 676 168 640 186 C 603 205 545 207 506 186 C 470 167 469 141 506 123 C 546 110 603 102 640 123"
                  stroke="#69a3b5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(501 120)" fill="#69a3b4">
                  <tspan fill="#69a3b4" x="31" y="23">Layered</tspan>
                  <tspan fill="#69a3b4" x="9" y="58">Architecture</tspan>
                </text>
              </g>
              <g id="g_71">
                <title>Generic Subdomains</title>
                <path
                  d="M 282 847 C 323 867 320 896 282 910 C 247 924 189 927 149 910 C 109 895 113 868 149 847 C 183 829 249 830 282 847 M 282 847 C 324 866 320 895 283 909 C 248 923 189 926 149 909 C 109 895 114 867 149 847 C 183 828 249 830 283 847"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(145 843)" fill="#8dbd5a">
                  <tspan fill="#8dbd5a" x="32" y="23">Generic</tspan>
                  <tspan fill="#8dbd5a" x="10" y="58">Subdomains</tspan>
                </text>
              </g>
              <g id="g_56">
                <title>Ubiquitous Language</title>
                <path
                  d="M 475 343 C 530 363 525 410 475 428 C 429 456 342 448 292 428 C 247 405 237 368 292 343 C 338 317 425 324 475 343 M 475 343 C 531 363 525 409 475 428 C 429 455 342 448 292 428 C 247 404 238 368 292 343 C 339 317 425 323 475 343"
                  stroke="#8ebd5b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="5"/>
                <text transform="translate(285 351)" fill="#8dbd5a">
                  <tspan fill="#8dbd5a" x="44" y="23">Ubiquitous</tspan>
                  <tspan fill="#8dbd5a" x="50" y="58">Language</tspan>
                </text>
              </g>
              <g id="g_7">
                <title>Big Ball of Mud</title>
                <path
                  d="M 605 965 C 638 980 638 997 605 1012 C 569 1024 510 1027 478 1012 C 439 996 445 981 478 965 C 513 955 568 951 605 965 M 605 965 C 638 979 638 996 605 1012 C 570 1024 511 1027 478 1012 C 439 996 445 981 478 965 C 513 955 568 951 605 965"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(474 975)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="7" y="19">Big Ball of Mud</tspan>
                </text>
              </g>
              <g id="g_8">
                <title>Anit-Corruption Layer</title>
                <path
                  d="M 847 979 C 879 991 878 1014 847 1026 C 812 1039 756 1038 720 1026 C 688 1015 686 989 720 979 C 758 967 815 968 847 979 M 847 979 C 880 990 879 1013 847 1026 C 812 1039 757 1038 720 1026 C 688 1014 687 988 720 978 C 759 967 815 967 847 978"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(717 974)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="3" y="19">Anti-Corruption</tspan>
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="44" y="47">Layer</tspan>
                </text>
              </g>
              <g id="g_10">
                <title>Separate Ways</title>
                <path
                  d="M 1072 965 C 1110 980 1107 1000 1072 1012 C 1037 1029 981 1026 945 1012 C 911 1003 910 979 945 965 C 982 951 1040 952 1072 965 M 1072 965 C 1110 980 1107 999 1072 1012 C 1037 1029 982 1026 945 1012 C 911 1002 910 978 945 965 C 982 950 1041 952 1072 965"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(942 975)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="8" y="19">Separate Ways</tspan>
                </text>
              </g>
              <g id="g_9">
                <title>Open Host Service</title>
                <path
                  d="M 1263 891 C 1294 901 1300 922 1263 938 C 1228 953 1169 955 1136 938 C 1103 924 1102 903 1136 891 C 1170 878 1227 876 1263 891 M 1263 891 C 1295 901 1300 922 1263 938 C 1228 953 1169 955 1136 938 C 1103 924 1102 903 1136 891 C 1170 878 1228 875 1263 891"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(1133 887)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="24" y="19">Open Host</tspan>
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="37" y="47">Service</tspan>
                </text>
              </g>
              <g id="g_11">
                <title>Published Language</title>
                <path
                  d="M 1532 884 C 1569 897 1565 922 1532 931 C 1497 942 1437 942 1405 931 C 1370 919 1369 899 1405 884 C 1437 868 1497 874 1532 884 M 1532 884 C 1569 897 1565 922 1532 931 C 1497 942 1438 942 1405 931 C 1371 919 1370 899 1405 884 C 1438 868 1497 874 1532 884"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(1402 880)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="27" y="19">Published</tspan>
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="27" y="47">Language</tspan>
                </text>
              </g>
              <g id="g_41">
                <title>Conformist</title>
                <path
                  d="M 1308 742 C 1346 756 1342 780 1308 789 C 1270 804 1212 805 1181 789 C 1148 774 1147 754 1181 742 C 1213 727 1271 727 1308 742 M 1308 742 C 1346 756 1343 779 1308 789 C 1270 803 1213 805 1181 789 C 1149 774 1147 754 1181 742 C 1214 727 1271 727 1308 742"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(1178 752)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="21" y="19">Conformist</tspan>
                </text>
              </g>
              <g id="g_43">
                <title>Customer / Supplier</title>
                <path
                  d="M 1228 620 C 1263 629 1265 657 1228 667 C 1189 677 1139 677 1101 667 C 1066 654 1065 635 1101 620 C 1139 609 1191 610 1228 620 M 1228 620 C 1264 629 1266 656 1228 667 C 1190 676 1140 677 1101 667 C 1066 653 1065 635 1101 619 C 1139 609 1192 610 1228 619"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(1097 615)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="23" y="19">Customer /</tspan>
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="33" y="47">Supplier</tspan>
                </text>
              </g>
              <g id="g_42">
                <title>Shared Kernel</title>
                <path
                  d="M 1146 520 C 1183 532 1178 554 1146 568 C 1108 583 1054 581 1019 568 C 985 557 984 534 1019 520 C 1054 504 1113 508 1146 520 M 1146 520 C 1183 532 1179 554 1146 567 C 1108 583 1055 580 1019 567 C 986 557 984 534 1019 520 C 1055 503 1113 508 1146 520"
                  stroke="#78a04d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"/>
                <text transform="translate(1016 530)" fill="#77a14d">
                  <tspan fontSize="18" fontWeight="600" fill="#77a14d" x="11" y="19">Shared Kernel</tspan>
                </text>
              </g>
            </g>
            <g id="Line_58">
              <path
                d="M 929 821 C 1026 832 1075 844 1134 874 M 929 821 C 1027 831 1076 843 1135 873"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_153">
              <path
                d="M 715 318 C 628 330 537 315 478 330 M 715 318 C 628 329 538 315 479 329"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="g_154">
              <rect x="581" y="829" width="114" height="110" fill="white" fillOpacity="0.6"/>
              <text transform="translate(586 834)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="16" y="17">{t(ddd.segregateMesses)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="6" y="42">{t(ddd.segregateMesses)[1]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="4121148e-19" y="67">{t(ddd.segregateMesses)[2]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="35" y="92">{t(ddd.segregateMesses)[3]}</tspan>
              </text>
            </g>
            <g id="g_155">
              <rect x="720" y="873" width="155" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(725 878)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="17">{t(ddd.insulate)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="10" y="42">{t(ddd.insulate)[1]}</tspan>
              </text>
            </g>
            <g id="g_156">
              <rect x="888" y="862" width="126" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(893 867)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="17">{t(ddd.freeTeams)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="16" y="42">{t(ddd.freeTeams)[1]}</tspan>
              </text>
            </g>
            <g id="g_157">
              <rect x="961" y="807" width="156" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(966 812)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="17">{t(ddd.supportMultipleClients)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="23" y="42">{t(ddd.supportMultipleClients)[1]}</tspan>
              </text>
            </g>
            <g id="g_159">
              <rect x="958" y="731" width="124" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(963 736)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="3" y="17">{t(ddd.minimizeTranslations)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="42">{t(ddd.minimizeTranslations)[1]}</tspan>
              </text>
            </g>
            <g id="g_160">
              <rect x="861" y="668" width="189" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(866 673)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="16" y="17">{t(ddd.alliedContexts)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="73896445e-20" y="42">{t(ddd.alliedContexts)[1]}</tspan>
              </text>
            </g>
            <g id="g_164">
              <rect x="813" y="603" width="183" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(818 608)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="13" y="17">{t(ddd.independentContexts)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="42">{t(ddd.independentContexts)[1]}</tspan>
              </text>
            </g>
            <g id="g_165">
              <rect x="455" y="684" width="126" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(460 689)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="17">{t(ddd.unifiedModel)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="18" y="42">{t(ddd.unifiedModel)[1]}</tspan>
              </text>
            </g>
            <g id="g_166">
              <rect x="580" y="643" width="210" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(585 648)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="17">{t(ddd.overviewRelationships)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="39" y="42">{t(ddd.overviewRelationships)[1]}</tspan>
              </text>
            </g>
            <g id="g_167">
              <rect x="302" y="663" width="134" height="85" fill="white" fillOpacity="0.6"/>
              <text transform="translate(307 668)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="17">{t(ddd.workAutonomous)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="20" y="42">{t(ddd.workAutonomous)[1]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="2" y="67">{t(ddd.workAutonomous)[2]}</tspan>
              </text>
            </g>
            <g id="g_168">
              <rect x="71" y="734" width="158" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(76 739)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="17">{t(ddd.avoidOverinvesting)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="24" y="42">{t(ddd.avoidOverinvesting)[1]}</tspan>
              </text>
            </g>
            <g id="g_169">
              <rect x="132" y="483" width="151" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(137 488)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="6679102e-19" y="17">{t(ddd.richModel)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="15" y="42">{t(ddd.richModel)[1]}</tspan>
              </text>
            </g>
            <g id="g_170">
              <rect x="561" y="376" width="166" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(566 381)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="32" y="17">{t(ddd.defineModelWithin)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="1" y="42">{t(ddd.defineModelWithin)[1]}</tspan>
              </text>
            </g>
            <g id="g_171">
              <rect x="511" y="306" width="146" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(516 311)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="0" y="17">{t(ddd.modelStructure)}</tspan>
              </text>
            </g>
            <g id="g_177">
              <rect x="637" y="213" width="161" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(642 218)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.isolateDomain)}</tspan>
              </text>
            </g>
            <g id="g_178">
              <rect x="870" y="337" width="155" height="17" fill="white" fillOpacity="0.6"/>
              <text transform="translate(871 350)" fill="#558595">{t(ddd.expressState)}</text>
            </g>
            <g id="g_179">
              <rect x="879" y="236" width="166" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(884 241)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.expressIdentity)}</tspan>
              </text>
            </g>
            <g id="g_180">
              <rect x="798" y="171" width="166" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(803 176)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.expressChange)}</tspan>
              </text>
            </g>
            <g id="g_181">
              <rect x="750" y="362" width="166" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(755 367)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.expressModel)}</tspan>
              </text>
            </g>
            <g id="Line_182">
              <path
                d="M 1138 454 C 1395 662 1576 510 1572 340 M 1138 454 C 1396 662 1577 509 1573 339"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="g_183">
              <rect x="1309" y="524" width="117" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1314 529)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.encapsulateWith)}</tspan>
              </text>
            </g>
            <g id="g_184">
              <rect x="1213" y="306" width="123" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1218 311)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="34" y="17">{t(ddd.encapsulateCreation)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="42">{t(ddd.encapsulateCreation)[1]}</tspan>
              </text>
            </g>
            <g id="g_185">
              <rect x="1291" y="187" width="95" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1296 192)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.root)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="2" y="42">{t(ddd.root)[1]}</tspan>
              </text>
            </g>
            <g id="g_186">
              <rect x="1230" y="139" width="109" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1235 144)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.accessWith)}</tspan>
              </text>
            </g>
            <g id="Line_187">
              <path
                d="M 1472 303 C 1434 327 1426 356 1420 390 M 1472 303 C 1435 326 1427 355 1421 389"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_188">
              <path
                d="M 1526 206 C 1509 172 1494 152 1445 113 M 1526 206 C 1510 171 1495 151 1445 112"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_189">
              <path
                d="M 1154 191 C 1145 125 1151 75 1064 60 M 1154 191 C 1146 124 1152 75 1065 60"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_190">
              <path
                d="M 1176 395 C 1235 388 1289 385 1338 408 M 1176 395 C 1236 387 1290 385 1338 407"
                markerEnd="url(#Arrow_Marker_2)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="Line_191">
              <path
                d="M 1264 268 C 1323 272 1378 266 1436 257 M 1264 268 C 1324 271 1379 265 1437 256"
                markerStart="url(#Arrow_Marker_4)"
                stroke="#69a3b4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="g_192">
              <rect x="1200" y="360" width="123" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1205 365)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="34" y="17">{t(ddd.encapsulateCreation)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="42">{t(ddd.encapsulateCreation)[1]}</tspan>
              </text>
            </g>
            <g id="g_193">
              <rect x="1370" y="322" width="123" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1375 327)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="34" y="17">{t(ddd.encapsulateCreation)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="42">{t(ddd.encapsulateCreation)[1]}</tspan>
              </text>
            </g>
            <g id="g_194">
              <rect x="1317" y="250" width="64" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1322 255)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="0" y="17">{t(ddd.encapsulate)}</tspan>
              </text>
            </g>
            <g id="g_195">
              <rect x="1434" y="138" width="109" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1439 143)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="1" y="17">{t(ddd.accessWith)}</tspan>
              </text>
            </g>
            <g id="g_196">
              <rect x="1066" y="84" width="148" height="60" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1071 89)" fill="#558595">
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="8" y="17">{t(ddd.stateChange)[0]}</tspan>
                <tspan fontSize="16" fontWeight="500" fill="#558595" x="0" y="42">{t(ddd.stateChange)[1]}</tspan>
              </text>
            </g>
            <g id="Line_200">
              <path
                d="M 426 524 C 390 511 368 497 361 469 M 426 524 C 390 510 369 496 361 468"
                markerEnd="url(#Arrow_Marker_1)"
                stroke="#8ebd5b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
            </g>
            <g id="g_201">
              <rect x="296" y="475" width="154" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(301 480)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="17">{t(ddd.namesEnter)}</tspan>
              </text>
            </g>
            <g id="g_209">
              <rect x="1260" y="954" width="124" height="35" fill="white" fillOpacity="0.6"/>
              <text transform="translate(1265 959)" fill="#658542">
                <tspan fontSize="16" fontWeight="500" fill="#658542" x="0" y="17">{t(ddd.formalize)}</tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </Section>
  )
}
