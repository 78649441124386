import {ITableOfContentsBranch} from "../components/TableOfContents";
import {projects} from "./Projects";
import {IProjectGroup, TProjects} from "../model/Project";
import {unixKnowHow} from "./UnixKnowHow";
import {siteMetaData} from "../siteMetaData";
import {abstract} from "./Abstract";
import {introduction} from "./Introduction";
import {branchenerfahrungen} from "./Branchenerfahrung";
import {clients} from "./Clients";
import {localizer, toId} from "../i18n";
import {roles} from "./Roles";
import {personal} from "./Personal";
import {preferences} from "./Preferences";
import {ILanguageBundle, Language} from "../model/LanguageBundle";
import {softSkillSection} from "./SoftSkills";
import {knowHow} from "./KnowHow";
import {developmentKnowHow} from "./DevelopmentKnowHow";
import {toolsetKnowHow} from "./ToolsetKnowHow";
import {IThings} from "../model/Things";
import {javaScalaVmKnowHow} from "./JavaScalaVmKnowHow";
import {domainDrivenDesign} from "./DomainDrivenDesign";
import {designPatternKnowHow} from "./DesignPatternKnowHow";
import {contactDetails} from "./ContactDetails";
import {azureKnowHow} from "./Azure";
import tocGenerator from "../tocGenerator";

type CreateNodeFn = (headerText: string | ILanguageBundle, nodes?: ITableOfContentsBranch[]) => ITableOfContentsBranch

// TODO: Create a Service that converts the data into a valid hierarchy of ITableOfContentsBranches.
const tocData = (language: Language, createNode: CreateNodeFn): ITableOfContentsBranch[] => {
  const t = localizer(language)

  const createNodeFromIThing = (thing: IThings): ITableOfContentsBranch =>
    createNode(thing.shortName || thing.name, [])

  const localizedFortbildungen = language === "de" ? "Fortbildungen" : "Professional Development"

  const toc = [
    createNode(introduction.header),
    {
      id: 'toc-download-cv-as-pdf',
      href: `${t({de: siteMetaData.downloadPaths.resume.pdf, en: siteMetaData.downloadPaths.resumeEn.pdf})}`,
      text: t({de: 'CV als PDF-Datei', en: 'CV as PDF file'}),
      type: 'application/pdf',
      nodes: []
    },
    {
      id: 'toc-download-cv-as-short-pdf',
      href: `${t({de: siteMetaData.downloadPaths.shortResume.pdf, en: siteMetaData.downloadPaths.shortResumeEn.pdf})}`,
      text: t({de: 'CV als PDF-Datei (Kurzversion)', en: 'CV as PDF file (short version)'}),
      type: 'application/pdf',
      nodes: []
    },
    createNode(abstract.header),
    createNode(branchenerfahrungen.shortHeader),
    createNode(clients.header),
    createNode(roles.title),
    createNode(personal.header),
    createNode(preferences.header),
    {
      id: `toc-item-${toId(localizedFortbildungen)}`,
      href: `#${toId(localizedFortbildungen)}`,
      text: localizedFortbildungen,
      nodes: []
    },
    createNode(softSkillSection.header),
    createNode(knowHow.header, [
      createNodeFromIThing(developmentKnowHow),
      createNodeFromIThing(azureKnowHow),
      createNodeFromIThing(javaScalaVmKnowHow),
      createNodeFromIThing(domainDrivenDesign),
      createNodeFromIThing(toolsetKnowHow),
      createNodeFromIThing(designPatternKnowHow),
      createNodeFromIThing(unixKnowHow)
    ])
  ]

  return toc;
}

// Projects
const projectsTableOfContentsEntries = (language: Language) => mapProjectToTocTree(projects, language)

function mapProjectToTocTree(projects: TProjects, language: Language): ITableOfContentsBranch[] {
  const t = localizer(language)
  const tableOfContentsBranch: ITableOfContentsBranch[] = [];
  for (const project of projects) {
    tableOfContentsBranch[tableOfContentsBranch.length] = {
      id: `toc-item-project-${project.id}`,
      href: `#project-${project.id}`,
      text: t(project.shortTitle) || t(project.title),
      nodes: (project as IProjectGroup).projects ? mapProjectToTocTree((project as IProjectGroup).projects, language) : []
    }
  }
  return tableOfContentsBranch
}

// The entire table of contents.
export const tableOfContents = (language: Language = "de") => {
  const createNode = tocGenerator(language).createNode
  return tocData(language, createNode).concat(projectsTableOfContentsEntries(language)).concat(createNode(contactDetails.header))
}
