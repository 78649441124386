import {InformationBox} from "../InformationBox";
import React from "react";
import {siteMetaData} from "../../siteMetaData";
import {DocType} from "../../data/DocType";
import {PersonType} from "../../model/PersonType";
import {graphql, useStaticQuery} from "gatsby";
import {getLocalizedDate, t} from "../../i18n";
import {versionInformation} from "../../data/VersionInformation";
import * as styles from "./VersionInformation.module.scss"
import {Language} from "../../model/LanguageBundle";

export const VersionInformation = ({personType, language}: { personType: PersonType, language: Language }) => {
  const data = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
    }
  `)

  const ressources = versionInformation({
    id: siteMetaData.getUniqueDocumentId(DocType.WEB_APP),
    currentDate: getLocalizedDate(language, data.currentBuildDate.currentDate)
  })

  const getIntroduction = () => {
    switch (personType) {
      case PersonType.FirstPersonSingular:
        return t(ressources.infoOtherVersions.firstPersonSingularWeb, language)
      case PersonType.ThirdPersonSingular:
        return `Dieses Curriculum Vitae wurde in der 3. Person Singular geschrieben, um die Verwendung der Textinhalte durch Recruiter zu erleichtern. 
        Es steht in weiteren Versionen zur Verfügung:`
    }
  }

  return (
    <div style={{padding: "1rem", marginTop: "-3rem"}}>
      <InformationBox>
        <h2>{/* Version information */}
          {t(ressources.header, language)}
        </h2>
        <div>
          <p dangerouslySetInnerHTML={{__html: getIntroduction()}}/>
          {language === "de" &&
          <ul>
            <li><span>1. Person Singular, als…</span>
              <ul>
                {language === "de" && PersonType.ThirdPersonSingular === personType &&
                <li><a href={t(ressources.web.url, language)}>Webseite</a></li>}
                <li>
                  <a href={t(ressources.pdf.longVersion.url, language)}
                       type="application/pdf">{t(ressources.pdf.longVersion.text, language)}
                  </a>
                </li>
                {language === "de" &&
                <li><a href={siteMetaData.urls.de.firstPerson.pdfShort} type="application/pdf">PDF in Kurzversion</a></li>}
              </ul>
            </li>

            {language === "de" &&
            <li>3. Person Singular, als…
              <ul>
                {PersonType.FirstPersonSingular === personType && <li><a href={siteMetaData.urls.de.thirdPerson.web}>Webseite</a></li>}
                <li><a href={siteMetaData.urls.de.thirdPerson.pdfLong} type="application/pdf">PDF in Langversion</a></li>
                <li><a href={siteMetaData.urls.de.thirdPerson.pdfShort} type="application/pdf">PDF in Kurzversion</a></li>
              </ul>
            </li>
            }
          </ul>}
          <div className={styles.currentVersionText}>{t(ressources.infoCreatedAt, language)}</div>
        </div>
      </InformationBox>
    </div>
  )
}
