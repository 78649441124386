import {IThings} from "../model/Things";

export const unixKnowHow: IThings = {
  id: "4638d3ad-b106-44bd-b714-811d73720b3f",
  name: {
    de: "Unix-Serveradministration und -konfiguration",
    en: "Unix server management"
  },
  shortName: {
    de: "Unix-Serveradministration",
    en: "Unix server management"
  },
  children: [
    {
      id: "aaca36bf-46e1-4ce9-a5f9-34cd2988d29f",
      name: {
        de: "Betriebssysteme",
        en: "Operating systems"
      },
      children: [
        {
          thingId: "03dd92ea-d146-4afd-8f4c-5bab2e525f27",
          source: "technology"
        },
        {
          thingId: "fbc2c357-24ed-449a-9b64-0522e7a02ff1",
          source: "technology"
        },
        {
          thingId: "d7da8e7c-fecf-4505-8332-68f4d06f5c8a",
          source: "technology"
        },
        {
          thingId: "4d244567-3459-40a1-a8ac-6e55d9b739d8",
          source: "technology"
        }
      ]
    },
    {
      id: "add8a6e3-b3b4-4976-9b2d-81465cd3ecef",
      name: {
        de: "Serverdienste",
        en: "Services"
      },
      children: [
        {
          thingId: "d3779cb7-a448-40a1-a20b-1a4e0cabd4ff",
          source: "technology"
        },
        {
          thingId: "a0a7cf91-2b7f-46d3-ab07-e41a97f04d7a",
          source: "technology"
        },
        {
          thingId: "9848a5e6-4024-4e40-b042-2efe83d4f81c",
          source: "technology"
        },
        {
          thingId: "9099d5c6-d046-4a30-b655-d752852b3ac3",
          source: "technology"
        },
        {
          thingId: "7569610f-cecb-4a2c-bd0e-19904a6cef01",
          source: "technology"
        },
        {
          thingId: "e0437c39-cba2-4714-a0e3-7bff950710bb",
          source: "technology"
        },
        {
          thingId: "99b18421-4bec-4203-bb79-dfae1b944fd3",
          source: "technology"
        },
        {
          thingId: "06aeb479-0d51-4d92-978a-df021a020c8f",
          source: "technology"
        },
        {
          thingId: "b84d32d0-7027-43f0-b7d2-7cf8e4462865",
          source: "technology"
        },
        {
          thingId: "13c68032-17d0-4bbc-8496-edd45b5e003e",
          source: "technology"
        },
        {
          thingId: "ca0b80b3-4d55-4b4b-a306-7ce928d2c1ab",
          source: "technology"
        },
        {
          thingId: "fe090153-3c22-430e-921f-a84eea3df65b",
          source: "technology"
        },
        {
          thingId: "38134f92-f6e5-47dc-be76-e177891063a0",
          source: "technology"
        },
        {
          thingId: "8b771135-5775-4503-9ff9-542f6ebc40d1",
          source: "technology"
        },
        {
          thingId: "b881fcfb-bede-425d-8041-1dbfa9e23bec",
          source: "technology"
        },
        {
          thingId: "f3f761f5-2ff4-40dc-8dd8-a1caf5ae06cb",
          source: "technology"
        },
        {
          thingId: "03586d9e-4ee8-467e-9a6c-7f0d510a4c49",
          source: "technology"
        },
        {
          thingId: "ac1d01df-27be-4bc8-936b-f48738b92be7",
          source: "technology"
        },
        {
          thingId: "48ad3061-4a22-4a10-b578-11fe4ca015f2",
          source: "technology"
        },
        {
          thingId: "95c8854f-521d-4219-b1e8-09044d6d20e7",
          source: "technology"
        },
        {
          thingId: "8f24e68e-65ab-4bf2-bcfd-54a1fa4dfdd8",
          source: "technology"
        },
        {
          thingId: "9aa0febd-fc7f-4cbc-86a6-bda2a2bf964a",
          source: "technology"
        },
        {
          thingId: "3e0dc387-eeab-42b8-9c91-0bd6525b5b7b",
          source: "technology"
        },
        {
          thingId: "8c65c88e-ec63-4a39-ad49-89ddc69c77cd",
          source: "technology"
        },
        {
          thingId: "6090cfaa-7755-45c4-b012-fbbef0249bec",
          source: "technology"
        },
        {
          thingId: "0e7ae9a7-857d-40a8-a165-5e98d48a30e6",
          source: "technology"
        }
      ]
    }
  ]
}
