import {IPersonal, SiteName, URL} from "../model/Personal";
import {countries} from "./Countries";
import {siteMetaData} from "../siteMetaData";

export const personal: IPersonal = {
  fullname: "Bastian Nolte",
  photoDescription: {
    de: "Photokomposition von Bastian Nolte",
    en: "photo composition of Bastian Nolte"
  },
  fullAddress: {
    de: "Grosswilstrasse 2, 6048 Horw / Luzern, Schweiz",
    en: "Grosswilstrasse 2, 6048 Horw / Lucerne, Switzerland"
  },
  phoneNumbers: {
    ch: "+41 44 586 97 10"
  },
  email: {
    de: "business@bastian-nolte.name",
    en: "business@bastian-nolte.name"
  },
  sites: new Map<SiteName, URL>()
    .set("Xing", "https://www.xing.com/profile/Bastian\\_Nolte")
    .set("Gulp", "https://www.gulp.ch/gulp2/home/profil/bastian-nolte")
    .set("LinkedIn", "https://www.linkedin.com/in/bastian-nolte"),
  nationality: {
    de: "Deutsch",
    en: "Germany"
  },
  firstWorkAssignment: siteMetaData.overallExperienceSinceYear,
  applicableIn: {
    countries: [countries.CH, countries.LI],
    preferredRegionsOfWork: [
      countries.CH,
      countries.SPECIAL_OR_REMOTE
    ]
  },
  leisureActivities: [
    {
      de: "Bergwandern",
      en: "mountain hiking"
    },
    {
      de: "Tauchen",
      en: "scuba diving"
    },
    {
      de: "Kayaking",
      en: "kayaking"
    }

  ],
  languages: {
    de: ["Deutsch (Muttersprache)", "Englisch"],
    en: ["German (mother tongue)", "English"]
  },
  awards: [
    {
      de: "Innovationspreis Public Private Partnership 2009 (PPP) für den fachübergreifenden Einsatz Neuer Medien",
      en: "Innovation Award Public Private Partnership 2009 (PPP) for the interdisciplinary use of New Media"
    }
  ],
  seeImprint: {
    de: "siehe Impressum",
    en: "see imprint"
  },
  header: {
    de: "Persönliches",
    en: "Personal"
  },
  labels: {
    name: {
      de: "Name",
      en: "Name"
    },
    address: {
      de: "Adresse",
      en: "Address"
    },
    awards: {
      de: "Auszeichnungen",
      en: "Awards"
    },
    itExperienceSince: {
      de: "IT-Erfahrung seit",
      en: "IT experience since"
    },
    professionalExperience: {
      de: "Berufserfahrung",
      en: "Professional Experience"
    },
    nationality: {
      de: "Nationalität",
      en: "Nationality"
    },
    languages: {
      de: "Sprachen",
      en: "Languages"
    },
    applicableIn: {
      de: "Einsetzbar",
      en: "Applicable"
    },
    preferredRegionOfWork: {
      de: "Bevorzugte Arbeitsregion",
      en: "Preferred region of work"
    },
    leisureActivities: {
      de: "Freizeit",
      en: "Leisure activities"
    },
    contactData: {
      de: "Kontaktdaten",
      en: "Contact details"
    },
    phone: {
      de: "Telefon",
      en: "Phone"
    },
    mobile: {
      de: "Mobil",
      en: "Mobile"
    },
    email: {
      de: "E-Mail",
      en: "Email"
    }
  }
}
