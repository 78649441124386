import React from "react";
import * as styles from "./Header.module.scss"
import PhotoBastianNolte from "../../assets/bastian-nolte-p-f.png"
import DevelopmentGraphics from "../../assets/development.svg"
import {header} from "../../data/Header";
import {localizer} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const CvHeader = ({language}: {language: Language}) => {
  const t = localizer(language)
  return (
    <header className={`${styles.container}`}>
      <div className={styles.content}>
        <div className={styles.documentTitle}>
          <span className="nowrap">Bastian Nolte</span> <span className={styles.emphasiseKnowledgeLevel}>{t(header.level) /* Expert */}</span>
        </div>
        <div className={styles.documentSubTitle} dangerouslySetInnerHTML={{__html: t(header.subHeader) /* Digital Curriculum Vitae / Profile */}}/>
        <div className={styles.roles} dangerouslySetInnerHTML={{__html: t(header.jobHeader1) /* IT Business Consultant & Coach */}}/>
        <div className={styles.roles} dangerouslySetInnerHTML={{__html: t(header.jobHeader2) /* Software-Architect & Full Stack Developer */}}/>
      </div>
      <img
        src={DevelopmentGraphics}
        className={styles.developmentGraphics}
        alt={t(header.alternativeTexts.vectorGraphic)}/>
      <img src={PhotoBastianNolte} className={styles.photo} alt={t(header.alternativeTexts.photo)}/>
    </header>
  )
}
