import {ICountry, ICountryCode} from "../model/Country";

export const countries: Record<ICountryCode, ICountry> = {
  CH: {
    countryCode: "CH",
    locale: "de_CH",
    country: {
      de: "Schweiz",
      en: "Switzerland"
    }
  },
  LI: {
    countryCode: "LI",
    locale: "de_LI",
    country: {
      de: "Liechtenstein",
      en: "Liechtenstein"
    }
  },
  D: {
    countryCode: "D",
    locale: "de_DE",
    country: {
      de: "Deutschland",
      en: "Germany"
    }
  },
  AU: {
    countryCode: "AU",
    locale: "de_AU",
    country: {
      de: "Österreich",
      en: "Austria"
    }
  },
  SPECIAL_REMOTE: {
    countryCode: "SPECIAL_REMOTE",
    locale: "SPECIAL",
    country: {
      de: "(remote)",
      en: "(remote)"
    }
  },
  SPECIAL_OR_REMOTE: {
    countryCode: "SPECIAL_REMOTE",
    locale: "SPECIAL",
    country: {
      de: "(oder remote)",
      en: "(or remote)"
    }
  }
}
