import {IKnowHow} from "../model/KnowHow";
import {siteMetaData} from "../siteMetaData";

export const knowHow: IKnowHow = {
  header: "Know-how",
  description: {
    de: "In den folgenden Kapiteln wird mein IT-Know-how nach Kategorien gruppiert dargestellt.",
    en: "The following chapters will give you an overview of my IT skills"
  },
  graphDescription: {
    de: "In den folgenden Kapiteln wird mein IT-Know-how nach Kategorien gruppiert - in Form von Graphen und alternativ in tabellarischer Form - dargestellt. Technologien die von mir zurzeit präferiert werden, sind im Graph durch einen orange hinterlegten Kreis markiert.",
    en: "The following chapters will give you an overview of my IT skills, visualised in graphs and tables. Nodes with a red background indicate that I currently prefer to work with that technology."
  },
  hintWebsite: {
    de: `Eine zusätzliche Darstellung als Graph finden Sie auf meinem digitalem Curriculum Vitae unter\n\\url{${siteMetaData.urls.de.firstPerson.web}#know-how}.`,
    en: `You can find an additional representation as a graph on my digital Curriculum Vitae at \n\\url{${siteMetaData.urls.en.firstPerson.web}#know-how}.`,
  }

}
