import React, {FunctionComponent} from "react";
import {ITechnologiesAndProducts} from "../../model/Project";
import * as styles from "./Projects.module.scss";
import {technologiesAndPatterns} from "../../data/TechnologiesAndPatterns";
import {Language} from "../../model/LanguageBundle";
import {localizer} from "../../i18n";
import {projectHeader} from "../../data/Projects";

type TechnologiesAndProductsProbs = {
  tnp?: Array<Readonly<ITechnologiesAndProducts>>
  language: Language
}

export const TechnologiesAndProducts: FunctionComponent<TechnologiesAndProductsProbs> = ({tnp, language}) => {
  if (!tnp) {
    return null;
  }
  const t = localizer(language)

  return (
    <>
      <div className={styles.sectionHeader}>{t(projectHeader.technologies)}</div>
      {
        tnp?.map((tnp, index) => {
          return (
            <React.Fragment key={index}>
              <div key={index} className={styles.rowHeader}>{t(tnp.header)}</div>
              <div>
                {
                  (tnp.tNpIds)
                    ? tnp.tNpIds.map((id, techIndex) => {
                      return (
                        <span key={techIndex}>
                          {technologiesAndPatterns.filter(t => t.id === id).map((tech, index) => {
                            return (
                              <React.Fragment key={index}>
                                {(techIndex > 0 ? ', ' : '')}{t(tech.name)}{(t(tech.aliases)) ? ` (${t(tech.aliases).join(', ')})` : ''}
                              </React.Fragment>
                            )
                          })}
                        </span>
                      )
                    })
                    : tnp.groups?.map((group, index) => {
                      return (
                        <div key={index}>
                          {(index > 0) ? <br/> : ''}
                          <div className={styles.groupHeader}>{t(group.header)}</div>
                          {group.tNpIds?.map((id, techIndex) => technologiesAndPatterns.filter(tech => tech.id === id).map((tech, index) => {
                            return (
                              <React.Fragment key={index}>
                                {(techIndex > 0 ? ', ' : '')}{t(tech.name)}{(t(tech.aliases)) ? ` (${t(tech.aliases).join(', ')})` : ''}
                              </React.Fragment>
                            )
                          }))}
                        </div>
                      )
                    })
                }
              </div>
            </React.Fragment>
          )
        })
      }
    </>
  )
}
