import {PersonType} from "./model/PersonType";
import {ReplacementType} from "./model/IReplacement";
import {firstPersonToThirdPersonTranslations} from "./data/FirstPersonToThirdPersonTranslations";

export const reshapeTheTextAsNeeded = (text?: string, personType?: PersonType): string => {
  if (text === undefined) return ""

  switch (personType || PersonType.FirstPersonSingular) {
    case PersonType.FirstPersonSingular:
      return text
    case PersonType.ThirdPersonSingular:
      return translateTo3rdPerson(text)
  }
}

const translateTo3rdPerson = (text: string): string => {
  firstPersonToThirdPersonTranslations.forEach((r) => {
    switch (r.type) {
      case ReplacementType.String:
      case ReplacementType.RepEx:
        text = text.replace(r.searchValue, r.replacement)
        break
      case ReplacementType.Word:
        text = text.replace(new RegExp(`(\\s|^)${r.searchValue}(?=\\s|[,.;:]|$)`, "g"), `$1${r.replacement}`)
        break
    }
  })
  return text
}
