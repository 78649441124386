import React from "react";
import {Section} from "../Section";
import * as styles from "./Personal.module.scss"
import PhotoComposition from "../../assets/bastian-nolte.jpg"
import {personal} from "../../data/Personal";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Personal = ({language}: {language: Language}) => {
  const t = localizer(language)
  // noinspection HtmlUnknownTarget
  return (
    <Section id={toId(t(personal.header))} title={t(personal.header)} navigation avoidPageBreak language={language}>
      <div
        className={styles.vcard}
      >
        <img className={styles.photoComposition} src={PhotoComposition} alt={t(personal.photoDescription)}/>
        <div className={styles.thead}>{t(personal.labels.name)}</div>
        <div>{personal.fullname}</div>
        <div className={styles.thead}>{t(personal.labels.awards)}</div>
        <div>{personal.awards.map(a => t(a)).join(", ")}</div>
        <div className={styles.thead}>{t(personal.labels.itExperienceSince)}</div>
        <div>{personal.firstWorkAssignment}</div>
        <div className={styles.thead}>{t(personal.labels.nationality)}</div>
        <div>{t(personal.nationality)}</div>
        <div className={styles.thead}>{t(personal.labels.languages)}</div>
        <div>{t(personal.languages).join(", ")}</div>
        <div className={styles.thead}>{t(personal.labels.applicableIn)}</div>
        <div>
          <ul>
            <li>{personal.applicableIn.countries.map(c => c.countryCode).join("-")}</li>
            <li>{personal.applicableIn.countries.map(c => t(c.country)).join(", ")}</li>
            <li>{t(personal.labels.preferredRegionOfWork)}: {personal.applicableIn.preferredRegionsOfWork.map(p => t(p.country)).join(" ")}</li>
          </ul>
        </div>
        <div className={styles.thead}>{t(personal.labels.leisureActivities)}</div>
        <div className={styles.leisureActivity}>
          <ul>
            <li>{personal.leisureActivities.map(l => t(l)).join(", ")}</li>
          </ul>
        </div>
        <div className={styles.thead}>{t(personal.labels.contactData)}</div>
        <div className={styles.thead}><a href="/impressum#top">{t(personal.seeImprint)}</a></div>
      </div>
    </Section>
  )
}
