import {IClients} from "../model/Client";

export const clients: IClients = {
  header: {
    de: "Kunden",
    en: "Clients"
  },
  clientList: [
    {
      id: 'suva',
      name: 'suva  Unfallversicherung',
      url: 'https://www.suva.ch',
      logo: {
        path: 'assets/clients/suva.svg',
        width: 90,
        height: 22
      }
    },
    {
      id: 'cssVersicherung',
      name: 'CSS Versicherung',
      url: 'https://www.css.ch',
      logo: {
        path: 'assets/clients/css-versicherung.svg',
        width: 41,
        height: 38
      },
    },
    {
      id: 'audi',
      name: 'Audi AG',
      url: 'https://audi.de',
      logo: {
        path: 'assets/clients/audi.svg',
        width: 60,
        height: 38
      }
    },
    {
      id: 'volkswagen',
      name: 'Volkswagen AG',
      url: 'https://www.volkswagenag.com',
      logo: {
        path: 'assets/clients/volkswagen.svg',
        width: 38,
        height: 38
      }
    },
    {
      id: 'capgemini',
      name: 'Capgemini',
      url: 'https://www.capgemini.com',
      logo: {
        path: 'assets/clients/capgemini.svg',
        width: 158,
        height: 38
      }
    },
    {
      id: 'spk',
      name: 'Stiftung Preußischer Kulturbesitz',
      url: 'https://www.preussischer-kulturbesitz.de',
      logo: {
        path: 'assets/clients/spk.svg',
        width: 56,
        height: 75
      }
    },
    {
      id: 'baSpandau',
      name: 'Bezirksamt Spandau',
      url: 'https://www.berlin.de/ba-spandau',
      logo: {
        path: 'assets/clients/bezirksamt-spandau.svg',
        width: 30,
        height: 45
      }
    },
    {
      id: 'dormakaba',
      name: 'dormakaba Gruppe',
      url: 'https://www.dormakaba.com',
      logo: {
        path: 'assets/clients/dormakaba.svg',
        width: 139,
        height: 15
      }
    },
    {
      id: 'msgSystems',
      name: 'msg systems',
      url: 'https://www.msg.group',
      logo: {
        path: 'assets/clients/msg-systems.svg',
        width: 114,
        height: 38
      }
    },
    {
      id: 'stabi',
      name: 'Staatsbibliothek zu Berlin',
      url: 'https://staatsbibliothek-berlin.de',
      logo: {
        path: 'assets/clients/stabi.png',
        width: 184,
        height: 49
      }
    },
    {
      id: 'xasia',
      name: 'crossasia.org',
      url: 'https://crossasia.org',
      logo: {
        path: 'assets/clients/xasia.svg',
        width: 100,
        height: 38
      }
    }
  ]
}
