import {Section} from "../Section";
import React, {Fragment} from "react";
import * as styles from "./Fortbildungen.module.scss"
import {graphql, useStaticQuery} from "gatsby";
import {ProfessionalDevelopments} from "../../__generated__/json-schemata";
import {PersonType} from "../../model/PersonType";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";
import {professionalDevelopments} from "../../data/ProfessionalDevelopments";

export const ProfessionalDevelopment = ({personType, language}: { personType: PersonType, language: Language }) => {
  const t = localizer(language)
  const data = useStaticQuery(graphql`
    query FurtherEducation {
      allDataYaml(filter: {type: {eq: "FurtherEducation"}}) {
        nodes {
          title
          language
          description
          content {
            date(formatString: "YYYY")
            title
            description
            organizer {
              company
              speaker
            }
          }
        }
      }
    }
  `)

  const fortbildungen: ProfessionalDevelopments = data.allDataYaml.nodes.find((n: {language: Language}) => n.language === language)

  return (
    <Section
      id={toId(fortbildungen.title)}
      title={fortbildungen.title}
      navigation
      language={language}>
      <div className="alternative">
        <p>{reshapeTheTextAsNeeded(fortbildungen?.description, personType)}</p>
      </div>
      <div className={styles.grid}>
        <div className={styles.rowHeader}>{t(professionalDevelopments.year)}</div>
        <div className={styles.rowHeader}>{t(professionalDevelopments.type)}</div>
        <div className={styles.rowHeader}>{t(professionalDevelopments.organizer)}</div>
        {
          fortbildungen.content.map((development, idx) => {
            return (
              <Fragment key={idx}>
                <div className={styles.date}>{development.date}</div>
                <div className={styles.title}>{development.title}</div>
                <div className={styles.company}>{development.organizer.company}</div>
                <div className={styles.description} dangerouslySetInnerHTML={{__html: development.description || " "}}/>
                <div className={styles.speaker}>{development.organizer?.speaker || " "}</div>
              </Fragment>
            )
          })
        }
      </div>
    </Section>
  )
}
