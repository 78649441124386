import React, {FunctionComponent} from "react"
import {Section} from "../Section";
import {ITableOfContentsBranch} from "../TableOfContents";
import * as styles from "./TableOfContentsFlat.module.scss";
import {Language} from "../../model/LanguageBundle";
import {localizer} from "../../i18n";

type TableOfContentsFlatProbs = {
  toc: ITableOfContentsBranch[],
  language: Language
}

export const TableOfContentsFlat: FunctionComponent<TableOfContentsFlatProbs> = ({toc, language}) => {
  const t = localizer(language)
  return (
    <div className={styles.flatTableOfContents}>
      <Section id="tableOfContents" title={t({de: 'Inhaltsverzeichnis', en: 'Table Of Contents'})} avoidPageBreak language={language}>
        <TableOfContentsFlatBranch toc={toc} language={language}/>
      </Section>
    </div>
  )
}

const TableOfContentsFlatBranch: FunctionComponent<TableOfContentsFlatProbs> = ({toc, language}) => {
  return (
    <ul>
      {toc.map((entry, idx) => {
        return (
          <li key={idx}>
            <a href={entry.href} type={entry.type || "text/html"}>{entry.text}</a>
            {entry.nodes && entry.nodes.length > 0 && <TableOfContentsFlatBranch toc={entry.nodes} language={language}/>}
          </li>
        )
      })}
    </ul>
  )
}
