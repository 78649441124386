import {IBranchenerfahrungen} from "../model/Branchenerfahrung";
import InsuranceImage from "../assets/insurance.svg"
import AutomotiveImage from "../assets/automotive.svg"
import GovermentImage from "../assets/goverment.svg"
import ItCloudImage from "../assets/it-cloud.svg"
import OpticalFibreImage from "../assets/optical-fiber.svg"
import EducationImage from "../assets/education.svg"
import ItSecurityImage from "../assets/it-security.svg"
import ServiceImage from "../assets/service.svg"
import ItServiceProviderImage from "../assets/it-service-provider.svg"

export const branchenerfahrungen: IBranchenerfahrungen = {
  header: {
    de: "Branchenerfahrung & Fachkompetenz",
    en: "Sector experience & expertise"
  },
  shortHeader: {
    de: "Branchenerfahrung",
    en: "Sector Experience"
  },
  erfahrungen: [
    {
      title: {
        de: "Versicherung",
        en: "Insurance"
      },
      image: InsuranceImage
    },
    {
      title: {
        de: "Informationstechnologie",
        en: "Information Technology"
      },
      image: ItCloudImage
    },
    {
      title: {
        de: "Automotive",
        en: "Automotive"
      },
      image: AutomotiveImage
    },
    {
      title: {
        de: "Dienstleister",
        en: "Service Provider"
      },
      image: ServiceImage
    },
    {
      title: {
        de: "IT Dienstleister",
        en: "IT Service Provider"
      },
      image: ItServiceProviderImage
    },
    {
      title: {
        de: "Öffentlicher Dienst",
        en: "Public Service"
      },
      image: GovermentImage
    },
    {
      title: {
        de: "Bildungsträger",
        en: "Educational Institutions"
      },
      image: EducationImage
    },
    {
      title: {
        de: "Telekommunikation",
        en: "Telecommunications"
      },
      image: OpticalFibreImage
    },
    {
      title: {
        de: "IT Sicherheit",
        en: "IT Security"
      },
      image: ItSecurityImage
    }
  ]
}
