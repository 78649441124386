import {IDateRange} from "./DateRange";
import {ILanguageBundle, ILanguageBundleArrayOrLocalized, ILanguageBundleOrLocalized} from "./LanguageBundle";

export type TProjects = (IProjectGroup | IProjectDescription)[]

export type ProjectHeader = {
  role: ILanguageBundle
  period: ILanguageBundle
  activities: ILanguageBundle
  technologies: ILanguageBundle
}

export interface IProjectGroup {
  id: string
  title: ILanguageBundleOrLocalized
  subtitle?: ILanguageBundleOrLocalized
  shortTitle?: ILanguageBundleOrLocalized
  description: ILanguageBundleOrLocalized
  projects: TProjects
  tags?: string[]
  filterMatch?: boolean
}

export interface IProjectDescription {
  id: string;
  title: ILanguageBundleOrLocalized
  subtitle?: ILanguageBundleOrLocalized
  shortTitle?: ILanguageBundleOrLocalized
  role: ILanguageBundleOrLocalized
  period: IDateRange
  description?: ILanguageBundleOrLocalized
  activities?: ILanguageBundleArrayOrLocalized
  technologiesAndProducts?: ITechnologiesAndProducts[]
  tags?: string[]
  filterMatch?: boolean
}

export interface ITechnologiesAndProducts {
  header: ILanguageBundleOrLocalized
  tNpIds?: string[]
  groups?: ITechnologiesAndProductGroup[]
}

export interface ITechnologiesAndProductGroup {
  header: ILanguageBundleOrLocalized
  tNpIds?: string[]
}

export const projectTypeGuards = {
  isProjectGroup: (groupOrDescription: IProjectGroup | IProjectDescription): groupOrDescription is IProjectGroup => {
    return (groupOrDescription as IProjectGroup).projects !== undefined
  },
  isProjectDescription: (groupOrDescription: IProjectGroup | IProjectDescription): groupOrDescription is IProjectDescription => {
    return (groupOrDescription as IProjectDescription).period !== undefined
  }
}
