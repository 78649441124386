export const maskControlCharacters = (input: string) => {
  return input
    .replace(/([&~}{#_$])/g, '\\$1')
    .replace(/™️/g, '\\texttrademark')
    .replace(/₂/g, '\\texorpdfstring{\\textsubscript{2}}{2}')
}

export const processHttpLinks = (text: string) => {
  return text
    .replace(/(https?:\/\/[A-z0-9_-]+(\.[A-z0-9_-]+)*(:[0-9]+)*\/?([A-z0-9%/_-]+)*)/g, "\\url{$1}")
}

export const processSquareBracketTags = (text: string) => {
  return text
    .replace(/^\n/, "")
    .replace(/\[{2}name]]([^[]+)\[{2}\/name]]/g, "\\textbf{$1}")
    .replace(/\[{2}role]]([^[]+)\[{2}\/role]]/g, "\\textbf{$1}")
    .replace(/\[{2}angebot]]([^[]+)\[{2}\/angebot]]/g, "\\textbf{$1}")
    .replace(/&#8209;/g, "-")
    // remove all [[...]] tags that have not yet been processed.
    .replace(/\[{2}[^\]]+]{2}/g, "")
}

export const truncateText = (params: {
  text?: string,
  length?: number,
  dots?: string
}) => {
  const {text, length, dots} = params
  if (text === undefined || length === undefined || text.length + 2 <= length) return params.text
  return text.slice(0, length) + dots || "…";
}
