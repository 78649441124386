import React, {FunctionComponent, useState} from "react";
import {IProjectDescription, IProjectGroup, projectTypeGuards, TProjects} from "../../model/Project";
import {PersonType} from "../../model/PersonType";
import {Language} from "../../model/LanguageBundle";
import {Projects} from "./Projects";
import {Chip} from "../Chip";
import AzureImage from "../../assets/azure.svg"
import InsuranceImage from "../../assets/insurance.svg"
import EducationImage from "../../assets/education.svg"
import JavaImage from "../../assets/java.svg"
import AngularImage from "../../assets/angular.svg"
import ResetImage from "../../assets/resetFilter.svg"
import * as styles from "./ProjectsContainer.module.scss";
import * as layoutContentAreaStyles from "../LayoutContentArea.module.scss";
import {localizer} from "../../i18n";

export type FilterController = {
  handleFiltering: (topic: string) => void
  resetFilter: () => void
}

type ProjectsProbs = {
  projects: TProjects
  personType: PersonType
  language: Language
  filterController?: (controller: FilterController) => void
}

export const ProjectsContainer: FunctionComponent<ProjectsProbs> = ({projects, personType, language, filterController }) => {
  /**
   * Wenn im Projektbaum ein Tag auf einem Knoten gesetzt ist, dann wird dieser im Filterbaum
   * in alle Kindknoten bis hinunter in die Blätter kopiert.
   * Wenn auf einem Kindknoten ein Tag gesetzt ist, dann wird dieser tag nach oben bis in die Wurzel kopiert.
   *
   * Beispiel:
   * Quell-Projektbaum                   Ziel-Projektbaum
   * - Project root                      - Project root
   *   - suva                              - suva
   *     tags: [insurance]                   tags: [insurance, education, azure, vue]
   *     - project 1                         - project 1
   *       tags: [education, azure]            tags: [insurance, education, azure]
   *     - project 2                         - project 2
   *       tags: [vue]                         tags: [insurance, vue]
   *   - volkswagen                        - volkswagen
   *     tags: [automotive]                  tags: [automotive, java]
   *     - project 1                         - project 1
   *       tags: [java]                        tags: [automotive, java]
   *     - project 2                         - project 2
   *       tags: []                            tags: [automotive]
   *
   */
  const propagateTags = (projects: TProjects, tagsToPropagate?: string[]) => {
    const concatTags = (origin?: string[], toAdd?: string[]): string[] | undefined => {
      const predicates = (() => {
        const noOrigin = () => !origin || origin.length < 1
        const nothingToAdd = () => !toAdd || toAdd.length < 1
        const neitherOriginNorSomethingToAdd = () => noOrigin() && nothingToAdd()

        return {
          noOrigin,
          nothingToAdd,
          neitherOriginNorSomethingToAdd
        }
      })()

      if (predicates.neitherOriginNorSomethingToAdd()) return
      if (predicates.nothingToAdd()) return origin

      return predicates.noOrigin()
        ? [...new Set(toAdd)] // Deduplicate
        : [...new Set(origin!.concat(toAdd!))] // Concat and deduplicate
    }

    return projects.reduce((acc, current: (IProjectGroup | IProjectDescription)) => {
      const currentProject = {...current}
      currentProject.tags = concatTags(currentProject.tags, tagsToPropagate) // Concat tags of current project with the tags of the parent project.
      currentProject.filterMatch = true // currently no filter is set.
      if (projectTypeGuards.isProjectGroup(currentProject)) {
        const p = propagateTags(currentProject.projects, currentProject.tags)
        currentProject.projects = p.projects
        currentProject.tags = concatTags(currentProject.tags, p.tags)
      }
      acc.projects.push(currentProject)
      currentProject.tags && acc.tags.push(...currentProject.tags)
      return acc
    }, {
      projects: [] as TProjects,
      tags: [] as string[]
    })
  }

  const denormalizedProjects = propagateTags(projects).projects

  const setFilterMatches = ({topic, projects}: {topic?: string, projects?: TProjects}) => {
    const filterByTopic = (projects: TProjects) => {
      return projects.map((current: (IProjectGroup | IProjectDescription)) => {
        const currentProject = {...current}
        currentProject.filterMatch = !topic || currentProject.tags?.includes(topic)
        if (projectTypeGuards.isProjectGroup(currentProject)) {
          currentProject.projects = filterByTopic(currentProject.projects)
        }
        return currentProject
      }, [] as TProjects)
    }

    setState({
      projects: filterByTopic(projects || state.projects),
      topic: topic || ""
    })
  }

  const handleFiltering = (topic?: string) => {
    setFilterMatches({topic: topic})
  }

  const resetFilter = () => {
    if (!state.topic) return
    setState({
      projects: denormalizedProjects,
      topic: ""
    })
  }

  filterController && filterController({
    resetFilter,
    handleFiltering
  })

  const t = localizer(language)

  const filter = [
    {
      tag: "azure",
      image: AzureImage,
      text: "Microsoft Azure"
    },
    {
      tag: "insurance",
      image: InsuranceImage,
      text: t({
        de: "Versicherung",
        en: "Insurance"
      })
    },
    {
      tag: "education",
      image: EducationImage,
      text: t({
        de: "Schulung",
        en: "Training"
      })
    },
    {
      tag: "angular",
      image: AngularImage,
      text: "Angular"
    },
    {
      tag: "java",
      image: JavaImage,
      text: "Java"
    }
  ]

  const [state, setState] = useState({
    projects: denormalizedProjects,
    topic: ""
  })

  return (
    <>
      <Projects projects={state.projects} personType={personType} language={language}>
        <div className={styles.projectFilter}>
          <h2 style={{marginBottom: "0.5em"}}>{t({
            de: "Klicke auf ein Thema, um die Projekte nach diesem Kriterium zu filtern.",
            en: "Click on a topic to filter the projects by that criteria."
          })}
          </h2>
          {filter.map((f) => {
            return (<Chip key={f.tag} topic={f.tag} image={f.image} alt={f.text} onClick={handleFiltering} isActive={f.tag === state.topic} customstyles={{fontSize: "0.8rem"}}>{f.text}</Chip>)
          })}
          {(state.topic) &&
            <Chip key={`reset`} image={ResetImage} alt="reset filter" onClick={resetFilter} customstyles={{fontSize: "0.75rem"}}>{t({
              de: "Filter zurücksetzen",
              en: "Reset filter"
            })}
            </Chip>}
        </div>
      </Projects>
      {(state.topic) &&
        <div className={styles.buttonContainer}>
          <div className={layoutContentAreaStyles.mainContainer} style={{justifyContent: "right"}}>
            <div style={{textAlign: "right"}}>
              <div className={styles.button} onClick={resetFilter}>{t({
                de: "Projektfilter zurücksetzen",
                en: "Reset project filter"
              })}
              </div>
            </div>
            <div className={layoutContentAreaStyles.asideContainer} style={{margin: 0}}>&nbsp;</div>
          </div>
        </div>
      }
    </>
  )
}
