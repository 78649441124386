import {Section} from "../Section";
import React from "react";
import * as styles from "./Clients.module.scss"
import AudiImage from "../../assets/clients/audi.svg"
import CapGeminiImage from "../../assets/clients/capgemini.svg"
import CssImage from "../../assets/clients/css-versicherung.svg"
import DormkabaImage from "../../assets/clients/dormakaba.svg"
import MsgSystemsImage from "../../assets/clients/msg-systems.svg"
import SpkImage from "../../assets/clients/spk.svg"
import StaatsbibliothekZuBerlinImage from "../../assets/clients/stabi.png"
import SuvaImage from "../../assets/clients/suva.svg"
import VolkswagenImage from "../../assets/clients/volkswagen.svg"
import BezirksamtSpandauImage from "../../assets/clients/bezirksamt-spandau.svg"
import CrossasiaImage from "../../assets/clients/xasia.svg"
import {t, toId} from "../../i18n";
import {clients} from "../../data/Clients";
import {Language} from "../../model/LanguageBundle";

export const Clients = ({language}: {language: Language}) => {
  const title = t(clients.header, language)
  const sectionId = toId(title)

  return (
    <Section id={sectionId} title={/* Kunden */ title} navigation avoidPageBreak language={language}>
      <div className={styles.content}>
        <div>
          <a href="https://www.suva.ch/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.suva} src={SuvaImage} alt="Logo der suva Unfallversicherung"/></a>
        </div>
        <div>
          <a href="https://www.css.ch/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.cssVersicherung} src={CssImage} alt="Logo der CSS Versicherung"/></a>
        </div>
        <div>
          <a href="https://audi.de/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.audi} src={AudiImage} alt="Logo der Audi AG"/></a>
        </div>
        <div>
          <a href="https://www.volkswagenag.com/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.volkswagen} src={VolkswagenImage} alt="Logo der Volkswagen AG"/></a>
        </div>
        <div>
          <a href="https://www.capgemini.com/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.capgemini} src={CapGeminiImage} alt="Logo von Capgemini"/></a>
        </div>
        <div>
          <a href="https://www.preussischer-kulturbesitz.de/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.spk} src={SpkImage} alt="Logo der Stiftung Preußischer Kulturbesitz"/></a>
        </div>
        <div>
          <a href="https://www.berlin.de/ba-spandau/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.baSpandau} src={BezirksamtSpandauImage} alt="Logo des Bezirksamt Spandau"/></a>
        </div>
        <div>
          <a href="https://www.dormakaba.com/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.dormakaba} src={DormkabaImage} alt="Logo der dormakaba Gruppe"/></a>
        </div>
        <div>
          <a href="https://www.msg.group/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.msgSystems} src={MsgSystemsImage} alt="Logo von msg systems"/></a>
        </div>
        <div>
          <a href="https://staatsbibliothek-berlin.de/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.stabi} src={StaatsbibliothekZuBerlinImage} alt="Logo der Staatsbibliothek zu Berlin"/></a>
        </div>
        <div>
          <a href="https://crossasia.org/" className="no-icon" rel="noopener noreferrer" target="_blank"><img className={styles.xasia} src={CrossasiaImage} alt="Logo von crossasia.org"/></a>
        </div>
      </div>
    </Section>
  )
}
