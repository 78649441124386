export const replaceParagraphsWithPTags = (content: string) => {
  const contentWithTags = content.replace(/\n([ \t]*\n)+/g, '</p><p>')
  return `<p>${contentWithTags}</p>`;
}

export const replaceSquareBracketsWithSpanTags = (content: string) => {
  const withOpeningSpanTags = content.replace(/\[\[([^/\]]*)\]\]/g, '<span class="emphasise-$1">')
  const withClosingSpanTags = withOpeningSpanTags.replace(/\[\[[^\]]+\]\]/g, "</span>")
  return withClosingSpanTags
}
