import React from "react";
import {Section} from "../Section";
import {TreeGraph, TreeGraphConfiguration} from "../TreeGraph";
import {softSkills, softSkillSection} from "../../data/SoftSkills";
import {TabGroup, TabHeader, TabView} from "../TabGroup";
import {ThingConverter} from "../../model/ThingConverter";
import {UnorderedTreeList} from "../UnorderedTreeList";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {PersonType} from "../../model/PersonType";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const SoftSkills = ({personType, language}: {personType: PersonType, language: Language}) => {
  const t = localizer(language)
  const rootNode = ThingConverter.createToTreeRepresentationConverter(language).convert(softSkills)
  const treeGraphConfiguration: TreeGraphConfiguration = {
    depthWidths: new Map().set(1, 150).set(2, 310)
  }
  return (
    <Section id={toId(t(softSkillSection.header))} title={t(softSkillSection.header)} subtitle={t(softSkillSection.subHeader)} navigation avoidPageBreak language={language}>
      <div className="alternative">
        <p>{reshapeTheTextAsNeeded(t(softSkillSection.description), personType)}</p>
      </div>
      <TabGroup>
        <TabHeader>Graph</TabHeader>
        <TabView>
          <TreeGraph rootNode={rootNode} configuration={treeGraphConfiguration} language={language}/>
        </TabView>
        <TabHeader>{t({ de: "Listenansicht", en: "List view"})}</TabHeader>
        <TabView>
          <UnorderedTreeList node={rootNode}/>
        </TabView>
      </TabGroup>
    </Section>
  )
}
