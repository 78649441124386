import {IThings} from "../model/Things";

export const toolsetKnowHow: IThings = {
  id: "e6b317f2-31bd-402d-bd79-ae3a955a5102",
  name: {
    de: "Werkzeuge / Tools",
    en: "Toolset"
  },
  shortName: "Toolset",
  priority: 2,
  children: [
    {
      id: "fa866c1c-5be3-4cdd-a8d7-5dd2eb27cb1b",
      name: {
        de: "Versionsverwaltung / SCM",
        en: "Version management / SCM"
      },
      priority: 2,
      children: [
        {
          thingId: "4919cc81-8ddf-45b7-b9e8-1f11eea34e67",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "2d79b4ff-a8bc-4902-91a1-112336eede51",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "d49f2580-bb1c-44b2-a318-1c2f204f3706",
          source: "technologiesAndPatterns",
        }
      ]
    },
    {
      id: "5fa3538d-50e7-4635-a49d-a8af5c93bcc4",
      name: {
        de: "Testautomatisierung",
        en: "Test automation"
      },
      priority: 2,
      children: [
        {
          thingId: "a1666796-df50-4d42-8199-f1bb3dfdd8c1",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "934ff905-5735-4ec8-8082-bc2f5fe74de8",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "85e9637c-c69e-4048-b652-04cd947a0629",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "c7dbc9ed-15e7-4f0f-9341-e3aba760319b",
          source: "technologiesAndPatterns",
          priority: 1
        },
        {
          thingId: "b47c4d26-48a8-415e-89d4-8f48592ab8b7",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "b15ffdd4-75ac-4ce3-b7ef-872600ca1948",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "76c5f05d-4d58-4f9c-ac4b-65415948db91",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "ef04bbae-2ea1-4bdc-9b48-1158b70a7b7b",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "88f4e7e3-2d3c-4521-8240-41da7c735055",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "896442b0-ffaa-44c3-8232-314c5253d9b6",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "a835acf9-9cba-4bed-b7f6-56b16e6a86a1",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "85268cc4-9a1e-4b5f-8f58-5cb362053ad8",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "ade0b691-1d17-4ad7-95ea-1f1ec48d4bcd",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "559fb1bf-2e16-4e7a-9794-289d9c77f9bf",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "d3ebaf2f-b952-482c-a802-49c94d7c4872",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "8e7306cf-2101-465c-ae1c-214ba7060496",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "287143e2-8957-4cab-a13d-3ac68e4c57ba",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "90605308-e856-438d-830f-9526b8d7de81",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "22336b76-1bec-41ad-9291-8a5200c0819f",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "f88f3d9b-d8f8-4661-92bd-8cdf800a2369",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "243818c8-9b5f-41b3-8c9b-e5e27c8ae33a",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "9848a5e6-4024-4e40-b042-2efe83d4f81c",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "a59cd1c1-b1b4-40b3-bfa0-3aa93171c588",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "8b016510-1dc7-4d79-a3b8-b24791e9336a",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "a70939fc-d011-4402-aa4d-c8a0539d2a91",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "15ebed4a-4d14-4cb8-9316-7a8abe418f26",
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "86634896-9cdb-4276-8ef8-cbbc8a724c0e",
      name: "Build Tools",
      priority: 2,
      children: [
        {
          thingId: "7ba7f7db-6cc9-4e6b-8f45-1f1eebaca972",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "facc5ef2-f3ca-4818-be7e-e8e808c70904",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "9099d5c6-d046-4a30-b655-d752852b3ac3",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "1636824b-3daf-4b42-83d6-222307dc8551",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "2ead01b3-570d-4e31-a912-eb0fb7c05d57",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "caad85de-9fd0-4aae-80c2-7d1b6653a9e8",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "214a1c0e-26b6-4016-8e25-a18a9afd46c5",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "4af91558-089e-47bb-80c9-059706ffbeef",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "60383d91-b207-40a5-a08f-57ab75788ee2",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "18c8d9d2-36a6-4a9b-834f-0fb25fec285e",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "2ab6eb2d-f4d5-45b0-a9d5-c88af0c9cdbc",
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "a90ec878-b906-459c-b289-35d7f5d8ff64",
      name: {
        de: "Koordination / Support",
        en: "Coordination / Support"
      },
      priority: 2,
      children: [
        {
          thingId: "53c75f04-5782-417d-b5a5-1a8128c5a5b8",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "c5c25c56-4ec2-4ab7-b3c4-380365fd68b7",
          source: "technologiesAndPatterns",
          priority: 2,
        },
        {
          thingId: "cf0ad17f-dfd3-4344-9551-991c4f8d8725",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "52a30cc9-3a9e-4a1b-ab45-75edaabc26e5",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "8bca58c9-d94e-4862-90dd-2462c199ff52",
          source: "technologiesAndPatterns",
        }
      ]
    },
    {
      id: "5cc02d2a-4ab2-4888-bd2e-05241d09d9c6",
      name: {
        de: "Dokumentation",
        en: "Documentation"
      },
      priority: 2,
      children: [
        {
          thingId: "afb5fc1f-2dba-43b8-b128-73f6ac3bc445",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "7ca359b0-aa54-46b1-9080-9dd45225d4aa",
          source: "technologiesAndPatterns",
          priority: 1,
        }
      ]
    },
    {
      id: "c4a7a5a3-ff97-4de0-b41f-2e7713507667",
      name: "Prototyping",
      children: [
        {
          thingId: "8e13ee2c-db76-4175-ad70-3634d16b686c", // Adobe XD
          source: "technologiesAndPatterns"
        },
        {
          thingId: "ef7e9026-7261-4297-bba7-f1ef29d63790", // Figma
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "7f6176ea-5b3e-4143-a20a-89a75e897ce1",
      name: {
        de: "Projektarchitektur",
        en: "Projektarchitektur"
      },
      priority: 2,
      children: [
        {
          thingId: "a35458f9-60ce-49aa-90da-8b7221379c89",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "c15ab6f5-be32-472f-b714-f93fb7c8ee44",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "c7947cd7-3697-409a-b7d5-f1ee9eb96d60",
          source: "technologiesAndPatterns",
          priority: 1,
        }
      ]
    },
    {
      id: "9d0aeeb5-4212-4170-8694-92f558940517",
      name: "Microsoft Office",
      priority: 2,
      children: [
        {
          thingId: "1da32bfe-9997-4276-af44-a89d4c81f3e8",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "08bea2ad-733c-4c44-a8e5-8e2d3b1a0c3a",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "9f15473b-a6dc-493a-b394-bead2b1cc1e3",
          source: "technologiesAndPatterns",
        }
      ]
    },
    {
      id: "109c8e11-677b-4dc4-9030-57eaaf3ecdf9",
      name: "SAP",
      priority: 2,
      children: [
        {
          thingId: "140daedd-432c-41cd-a1cc-22f8ec5ea59c",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "80d8e3b2-9a5c-4b37-9be3-3a4be6807f79",
          source: "technologiesAndPatterns",
        },
        {
          thingId: "e8b18f07-9aaa-4b73-9b93-090f7453980b",
          source: "technologiesAndPatterns",
        }
      ]
    },
    {
      id: "cdb40e9b-8d24-451a-a073-e5e448130d37",
      name: {
        de: "Protokollierung, Monitoring & Reporting",
        en: "Logging, monitoring and reporting"
      },
      priority: 2,
      children: [
        {
          thingId: "117bf495-07c4-4943-8168-3f55b0fd62d3",
          source: "technologiesAndPatterns",
        }
      ]
    }
  ]
}
