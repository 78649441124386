import {IVersionInformation} from "../model/VersionInformation";
import {siteMetaData} from "../siteMetaData";

export const versionInformation = ({id, currentDate, downloadPath = ""}: {id: string, currentDate: string, downloadPath?: string}) => {
  const versionInformation: IVersionInformation = {
    header: {
      de: "Versions-Informationen",
      en: "Version information"
    },
    infoOtherVersions: {
      firstPersonSingularWeb: {
        de: "Dieses Curriculum Vitae steht in weiteren Versionen zur Verfügung:",
        en: `This Curriculum Vitae is also available as <a href="${siteMetaData.urls.en.firstPerson.pdfLong}">PDF</a> and <a href="${siteMetaData.urls.en.firstPerson.pdfShort}">PDF in short version</a>.`
      },
      firstPersonSingularPDF: {
        de: "Dieses Curriculum Vitae steht in weiteren Versionen zur Verfügung:",
        en: `This Curriculum Vitae is also available as website under \\url{${siteMetaData.urls.en.firstPerson.web}}.`
      },
      firstPersonSingularShortPDF: {
        de: `Dieses Curriculum Vitae ist eine Kurzversion. 
  
    Es steht in weiteren Versionen zur Verfügung:`,
        en: `This Curriculum Vitae is a short version. 
  
    It is available in further versions:`
      }
    },
    infoLatestVersion: {
      de: `Die aktuellste Version dieses Dokuments kann unter der Webadresse\\linebreak\n  \\url{${siteMetaData.siteUrl}${downloadPath}} heruntergeladen werden.`,
      en: `The latest version of this document can be downloaded from the web address \\url{${siteMetaData.siteUrl}${downloadPath}}.`
    },
    infoCreatedAt: {
      de: `Dieses Dokument mit der Id «${id}» wurde zuletzt am ${currentDate} geändert.`,
      en: `This document with id «${id}» was modified at ${currentDate}.`
    },
    pdf: {
      longVersion: {
        text: {
          de: "PDF in Langversion",
          en: "PDF long-version"
        },
        url: {
          de: siteMetaData.urls.de.firstPerson.pdfLong,
          en: siteMetaData.urls.en.firstPerson.pdfLong
        }
      }
    },
    web: {
      url: {
        de: siteMetaData.urls.de.firstPerson.web,
        en: siteMetaData.urls.en.firstPerson.web
      }
    }
  }

  return versionInformation
}
