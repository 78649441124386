export const abstract = {
  header: {
    de: "Zusammenfassung",
    en: "Abstract"
  },
  content: {
    de: `
Ich war in den letzten 10 Jahren hauptsächlich in Projekten der Bereiche Versicherung, Automotive, Industrie, Verwaltung und Bildung 
involviert. Zu meinen Kunden zählen unter anderem die Capgemini Schweiz AG, die Schweizerische Unfallversicherungsanstalt (Suva) und die 
CSS Versicherung, die Volkswagen AG, Siemens, msg Systems, T-Systems und verschiedene Institutionen, darunter die 
Staatsbibliothek zu Berlin, das Ostasiatische Institut und verschiedene Landesbehörden und Stadtverwaltungen.

In der Projektarbeit nutzen wir Methoden, Frameworks und Mechanismen der agilen Entwicklung, wie Scrum, LeSS, Continuous Integration, 
testgetriebene Entwicklung, Mob- und Pair-Programming, in Verbindung mit den Prozess unterstützender Software wie Atlassian JIRA, 
Confluence und Team City.

Ich verfüge über ein breites Spektrum an Know-how in verschiedenen Programmiersprachen. Meine präferierten Sprachen sind TypeScript und 
F#.

Weiterhin verfüge ich über langjährige Erfahrung in der Projektplanung und -koordination, sowie der Administration diverser Server- 
und Clouddienste, wie zum Beispiel Microsoft Azure.

Ich beherrsche den Umgang mit den gängigsten Datenbanken. Kenntnisse über Spring (Boot), Java EE und den Einsatz verschiedener Frameworks und Libraries, 
wie beispielsweise MicroProfile, vue.js und Angular runden mein Profil ab.
`,
    en: `
In the last ten years, I have been mainly involved in projects in the insurance, automotive, industry, administration and education sectors. My clients include companies like Capgemini Schweiz AG, the Swiss Accident Insurance Fund (Suva), CSS Health Insurance, Volkswagen, Siemens, msg Systems, T-Systems and various institutions, like the Berlin State Library, the East Asian Institute, and various state authorities and councils.

We use methods, frameworks and mechanisms of agile development, such as Scrum, LeSS, Continuous Integration, test-driven development, mob- and pair-programming, combined with process supporting software like Atlassian JIRA, Confluence and Team City.

I have a wide range of expertise in various programming languages. My preferred languages are TypeScript and F#.

Furthermore, I have many years of experience in project planning and coordination and the administration of various server and cloud services, such as Microsoft Azure.

I am proficient in the use of the most common databases. Knowledge in Spring (Boot), Java EE and various frameworks and libraries, such as MicroProfile, vue.js and Angular, completes my profile.
`
  }
}
