import {ILanguageBundle, ILanguageBundleArray} from "./LanguageBundle";

export type TLinkOrThing = ILink | IThings
export type TLinkOrThingLocalized = ILink | IThingsLocalized

export interface IThings extends IThing {
  children?: TLinkOrThing[]
}

export interface IThingsLocalized extends IThingLocalized {
  children?: TLinkOrThingLocalized[]
}

export interface ILink {
  source: string
  thingId: UUID
  priority?: number
}

export type IThing = IThingBase<ILanguageBundle | string, string[] | ILanguageBundleArray>
export type IThingLocalized = IThingBase<string, string[]>

export interface IThingBase<T extends ILanguageBundle | string, TA extends string[] | ILanguageBundleArray> {
  id: UUID
  name: T
  shortName?: T
  aliases?: TA
  description?: T
  urls?: string[]
  wikiUrl?: string
  priority?: number
}

export type UUID = string

export const thingsTypeGuards = {
  isThings: (whatever?: IThings | IThing | IThingLocalized | ILink): whatever is IThings => {
    return (whatever as IThings)?.children !== undefined;
  },
  isThing: (whatever: IThings | IThing | IThingLocalized | ILink): whatever is IThing => {
    return (whatever as IThing).id !== undefined && (whatever as IThing).name !== undefined;
  },
  isLocalizedThing: (whatever: IThings | IThing | IThingLocalized| ILink): whatever is IThingLocalized => {
    return (whatever as IThing).id !== undefined && (whatever as IThing).name !== undefined;
  },
  isLink: (whatever: IThings | IThing | IThingLocalized | ILink): whatever is ILink => {
    return (whatever as ILink).source !== undefined && (whatever as ILink).thingId !== undefined;
  },
  isILanguageArray: (whatever: string[] | ILanguageBundleArray | undefined): whatever is ILanguageBundleArray => {
    const obj = (whatever as ILanguageBundleArray)
    return (whatever !== undefined && obj.de !== undefined && Array.isArray(obj.de))
  },
  isStringArray: (whatever: any | undefined): whatever is string[] => {
    return (whatever !== undefined && Array.isArray(whatever) && (whatever.length === 0 || typeof whatever[0] === "string"))
  }
}
