import {branchenerfahrungen} from "../../data/Branchenerfahrung";
import React from "react";
import {Section} from "../Section";
import {Chip} from "../Chip";
import {t, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Branchenerfahrung = ({language}: {language: Language}) => {
  const title = t(branchenerfahrungen.header, language)
  const sectionId = toId(t(branchenerfahrungen.shortHeader, language))
  const erfahrungen = branchenerfahrungen.erfahrungen;

  return (
    <Section id={sectionId} title={title} navigation avoidPageBreak language={language}>
      {
        erfahrungen.map((erfahrung, idx) => <Chip key={idx} image={erfahrung.image} alt={`Grafische Darstellung der Branche ${t(erfahrung.title, language)}`}>{t(erfahrung.title, language)}</Chip>)
      }
    </Section>
  )
}
