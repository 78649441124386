import * as styles from "./Kontakt.module.scss"
import React from "react";
import {Section} from "../Section";
import {contactDetails} from "../../data/ContactDetails";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const ContactDetails = ({language}: {language: Language}) => {
  const t = localizer(language)
  return (
    <Section id={toId(t(contactDetails.header))} title={t(contactDetails.header)} subtitle={t(contactDetails.introduction)} navigation avoidPageBreak language={language}>
      <div className={styles.contact}>
        <img src={t(contactDetails.contactInformationImage)} className={styles.furtherInformation} alt={t(contactDetails.contactInformationImageAlt)}/>
        {
          React.Children.toArray(contactDetails.profilePages.map((profile) => {
            return (
              <>
                <a href={profile.url} className="no-icon" rel="noopener" target="_blank"><img src={profile.badge} alt={t(profile.alternativeText)}/></a>
                <a href={profile.url} rel="noopener" target="_blank">{t(profile.urlText) || profile.url}</a>
              </>
            )
          }))
        }
      </div>
    </Section>
  )
}
