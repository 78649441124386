import {IRoles} from "../model/Role";
import {siteMetaData} from "../siteMetaData";

export const roles: IRoles = {
  title: {
    de: "Rollen",
    en: "Roles"
  },
  years: {
    de: "Jahre",
    en: "years"
  },
  roles: [
    {
      title: {
        de: "Coach \n& \nTrainer",
        en: "Coach \n& \nTrainer"
      },
      durationInYears: 4
    },
    {
      title: {
        de: "IT \nBusiness \nConsultant",
        en: "IT \nBusiness \nConsultant"
      },
      durationInYears: siteMetaData.overallExperienceInYears - 13
    },
    {
      title: {
        de: "Software-Architekt \n& (Technischer) \nProjektleiter",
        en: "Software Architect \n& (Technical) \nProject Lead"
      },
      durationInYears: siteMetaData.overallExperienceInYears - 11
    },
    {
      title: {
        de: "Software \nEntwickler",
        en: "Software \nDeveloper"
      },
      durationInYears: siteMetaData.overallExperienceInYears
    },
  ]
}

export const rollenZusammenfassung = {
  de: `${roles.roles[3].durationInYears} Jahre Erfahrung im IT-Bereich, davon etwa ${roles.roles[1].durationInYears} Jahre als Business Consultant und ${roles.roles[0].durationInYears} als Coach. Rollen in (technischer) Projektleitungsfunktion und als Architekt habe ich zirka ${roles.roles[2].durationInYears} Jahre lang ausgeübt.`,
  en: `${roles.roles[3].durationInYears} years of experience in the IT sector, of which about ${roles.roles[1].durationInYears} years as a business consultant and ${roles.roles[0].durationInYears} as a coach. I have worked in (technical) project management and architectural roles for about ${roles.roles[2].durationInYears} years.`
}
