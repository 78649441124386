import {IThings} from "../model/Things";

export const developmentKnowHow: IThings = {
  id: "5b496dff-28c6-4392-9bf4-aa4de0b47774",
  name: {
    de: "Entwicklung (allgemein)",
    en: "Development (in general)"
  },
  shortName: {
    de: "Entwicklung",
    en: "Development"
  },
  priority: 2,
  children: [
    {
      id: "0f1da1c3-d398-47f2-844c-01c1d64c9435",
      name: "Cloud",
      priority: 2,
      children: [
        {
          source: "technologies", // Microsoft Azure Cloud Computing Services
          thingId: "4747773f-dfa3-4c26-a303-05d304b8d4c0",
          priority: 1
        },
        {
          source: "technologies",
          thingId: "2ea82c55-70c5-43e4-bc28-2804eeb7e193",
          priority: 1
        },
        {
          source: "technologies",
          thingId: "48db98b4-79f1-48fd-97e8-ae6c9917725d"
        },
        {
          source: "technologies",
          thingId: "c8409eae-9401-49da-8ab2-96752f1b71af"
        }
      ]
    },
    {
      id: "6b708252-15d9-43a8-8b25-55be89be8554",
      name: {
        de: "Programmiersprachen",
        en: "Programming languages"
      },
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "4c437fe3-9160-435d-9f03-dc5153bf47f2", // F#
          priority: 1
        },
        {
          source: "technologies",
          thingId: "d909d9bb-677c-4223-957b-fd64832a6eee",
          priority: 1
        },
        {
          source: "technologies",
          thingId: "cd1c526f-7af8-4bee-9761-fde0be5645e4",
          priority: 1
        },
        {
          source: "technologies",
          thingId: "2cb9e317-7fb3-420a-a53c-128b47ea726b",
          priority: 2
        },
        {
          source: "technologies",
          thingId: "a4218084-dc1f-482a-bfd5-8d451ccf0f5a",
        }
      ]
    },
    {
      id: "545c095e-cb7d-43eb-9adf-082b0e80ea7f",
      name: "Frameworks",
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "445df01b-9af2-483c-89b6-dd03a3c62d55",
          priority: 1
        },
        {
          thingId: "59966621-d585-446c-ac91-73907034ca15",
          source: "technologiesAndPatterns",
          priority: 1,
        },
        {
          thingId: "406f8ef4-2801-46a0-9bac-a2765d3694d8",
          source: "technologiesAndPatterns",
          priority: 1,
        }
      ]
    },
    {
      id: "1cb321d9-d846-4164-9f76-3120e5cfdc04",
      name: {
        de: "Skriptsprachen",
        en: "Script Languages"
      },
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "589cbeac-a61f-48e8-a1fd-a097678447be",
          priority: 1
        },
        {
          source: "technologies",
          thingId: "10729383-5c02-4de4-9ff9-d8976b4f8374",
        },
        {
          id: 'a831e4d6-08bd-458a-a725-9dc28aef156c',
          name: "Command Line Interfaces",
          children: [
            {
              id: 'ffdf6ba9-71cd-47ef-975e-0c60ba38fa63',
              name: "Bash/ksh/csh/zsh/...",
            },
            {
              source: "technologies",
              thingId: "45382632-1bbb-4c14-b97b-a14fe4e6dabb",
            },
            {
              source: "technologies",
              thingId: "6a045b7e-bc61-4c59-8a3e-766541e6bcc4"
            }
          ]
        }
      ]
    },
    {
      id: "fdd996bd-4922-4833-aeac-4fda098ba81a",
      name: {
        de: "Datenbanken",
        en: "Databases"
      },
      priority: 2,
      children: [
        {
          id: "e43dcb67-2a07-4fdb-bf15-5b0c5cb02954",
          name: "NoSQL",
          children: [
            {
              source: "technologies",
              thingId: "41f0309a-242b-4ee2-90df-bd90d3b11713", // Azure Cosmos DB
            },
            {
              source: "technologies",
              thingId: "13e7e3e8-86cf-454b-9777-fe7250103773", // Neo4J
            },
            {
              source: "technologies",
              thingId: "c5c4035f-39ed-4fbd-b75b-0d1d6fddc72a" // MongoDB
            }
          ]
        },
        {
          id: "9696750c-c27c-4091-91c6-c9e77e7429da",
          name: {
            de: "Relationale Datenbanken",
            en: "Relational databases"
          },
          children: [
            {
              source: "technologies",
              thingId: "81447678-22d2-4e66-b20a-0b6e1dc8629e",
            },
            {
              source: "technologies",
              thingId: "eade989d-bea5-492c-afba-684366cdf76e",
            },
            {
              source: "technologies",
              thingId: "58e6efa3-9c09-4364-b853-7c458b5d37c7",
            },
            {
              source: "technologies",
              thingId: "14454175-d253-4382-8385-9daa11a5c453",
            }
          ]
        },
        {
          id: "aef21d59-fb8b-40ea-9885-43c3a9bf6058",
          name: {
            de: "Verzeichnisdienste",
            en: "Directory services"
          },
          children: [
            {
              source: "technologies",
              thingId: "ca0b80b3-4d55-4b4b-a306-7ce928d2c1ab",
            }
          ]
        },
        {
          id: "3b20b523-2eda-4f0a-93a5-df8b3edcd67e",
          name: {
            de: "Datenbank- / Abfragesprachen",
            en: "Database- / query languages"
          },
          children: [
            {
              source: "technologies",
              thingId: "547efee1-a88b-4008-bf35-7d742aa34598",
            },
            {
              source: "technologies",
              thingId: "4ea7715c-e6db-4380-81db-91bd8d81ed8c",
            },
            {
              source: "technologies",
              thingId: "021dcb4a-0cd4-4654-a7b7-a8c2b0e30b51",
            },
            {
              source: "technologies",
              thingId: "8a9cc04a-3dd3-454d-8839-91440b4fe68c",
            },
            {
              source: "technologies",
              thingId: 'c774a0d5-a448-4ec1-a566-7be02dd33808'
            },
            {
              source: "technologies",
              thingId: "a401637d-ad5f-4a50-abea-69c0647a929d"
            },
            {
              source: "technologies",
              thingId: "6fa04dd0-1aab-4bb7-b689-eae075b1d2c6",
            },
            {
              source: "technologies",
              thingId: "07345956-4ce1-4316-95a9-5d38243441b5",
            },
            {
              source: "technologies",
              thingId: "32f0c50e-6239-480b-af44-0108018ecbb7",
            },
            {
              source: "technologies",
              thingId: "8e060244-223b-4107-b12d-e528ca12cb2e"
            },
            {
              source: "technologies",
              thingId: "529f5627-e98e-496f-9fa1-96eaeff7da47"
            }
          ]
        }
      ]
    },
    {
      id: "a4539cee-5ec7-4025-a6c8-57e3e5d6e70e",
      name: "Container",
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "90540b8a-d85e-482e-880d-d207d043728e"
        },
        {
          source: "technologies",
          thingId: "60383d91-b207-40a5-a08f-57ab75788ee2",
        }
      ]
    },
    {
      id: "c569fc29-af18-4dd9-9294-408459b5d452",
      name: {
        de: "Kommunikation",
        en: "Communication"
      },
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "57574a4d-4edf-46fc-8a3c-e5c811885906" /* Azure Service Bus */
        },
        {
          source: "technologies",
          thingId: "340b0a7f-91c7-443c-9112-36b8c6848815" /* Azure Event Grid */
        },
        {
          source: "technologies",
          thingId: "3a8d69bc-c435-40fd-8e8f-855915fca03c",
        }
      ]
    },
    {
      id: "a054a6f3-2f1b-4a1d-940f-2dd1661df56f",
      name: "Web",
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "674e554a-4deb-41fa-8d5f-993f31b3f723",
          priority: 2
        },
        {
          source: "technologies",
          thingId: "0fc87388-95c8-471e-a229-275814834ff3",
          priority: 2
        },
        {
          source: "technologies",
          thingId: "b0ba5260-70bd-4b9f-b04a-fb2d4bd37c34",
        },
        {
          source: "technologies",
          thingId: "e1750306-e3d5-4efa-88bf-910758902d5c",
        },
        { // JavaScript, ECMA
          source: "technologies",
          thingId: "10729383-5c02-4de4-9ff9-d8976b4f8374",
          children: [
            {
              source: "technologies",
              thingId: "310d4ac3-4b55-4949-a8d2-b2586d9f0551",
            },
            {
              source: "technologies",
              thingId: "5d8dd90c-7421-45e9-8048-375d8e952cfe"
            },
            {
              source: "technologies",
              thingId: "bc611a2f-d07a-4af0-97b2-8312749ea847",
            },
            {
              source: "technologies",
              thingId: "424dd38a-c95e-403e-ba98-1f31651adff6",
            },
            {
              source: "technologies",
              thingId: "ff25f6c6-d4e3-4cf6-b200-4194ae7830c9"
            },
            {
              source: "technologies",
              thingId: "b2c1140f-bfea-47c5-b9a6-0a74d2f6dcfd"
            },
            {
              source: "technologies",
              thingId: "994e3301-a720-46b3-8367-03ffed28a203"
            },
            {
              source: "technologies",
              thingId: "7d4a3e25-170f-4d03-a268-4347897c0f4e",
            },
            {
              source: "technologies",
              thingId: "21ba9b07-f825-44db-b9f1-107f627cb15e",
            },
            {
              source: "technologies",
              thingId: "9f8dd064-c3c0-4a37-bd07-2d007f0becd7"
            },
            {
              source: "technologies",
              thingId: "b6a688f5-0755-4111-965b-38ba85cfaef3"
            }
          ]
        },
        {
          source: "technologies",
          thingId: "c543460c-6971-4bf0-82e1-474c2c2cb1dd",
        }
      ]
    },
    {
      id: "04525333-7496-40b5-8e0c-a5ecaab60f97",
      name: {
        de: "Auszeichnungssprachen",
        en: "Markup languages"
      },
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: "0fc87388-95c8-471e-a229-275814834ff3",
        },
        {
          source: "technologies",
          thingId: "86cc95fa-80f8-47da-85b1-9796fbbf89be",
        },
        {
          source: "technologies",
          thingId: "e6336288-4d5b-4aa5-884d-be0fddb14edc",
        },
        {
          source: "technologies",
          thingId: "5caf9fef-8ab9-4560-8aeb-4026c3761d02",
        },
        {
          source: "technologies",
          thingId: "3777783f-d014-4439-a39c-f303047f825b"
        },
        {
          source: "technologies",
          thingId: "b9bd7699-6ebf-4290-8efe-a4b0dd447918",
        },
        {
          source: "technologies",
          thingId: "a3fb712d-c5ed-42d4-b017-a1dce031cf09",
        },
        {
          source: "technologies",
          thingId: "7fe3b09e-abd0-49ff-a656-db849f33808c",
        },
        {
          source: "technologies",
          thingId: "aaa7ec8e-2d77-4d3a-b7c4-988a0a2bee2a",
        },
        {
          source: "technologies",
          thingId: "396e46cc-b0ea-400f-b4f3-beb208f2eb96",
        },
        {
          source: "technologies",
          thingId: "9b587627-a50d-4cae-b35c-8b687b1311e0"
        },
        {
          source: "technologies",
          thingId: "dd7c5c9f-7730-4b8c-9220-21032866d540"
        }
      ]
    },
    {
      id: "5319d1d1-0edc-4465-993d-955294028169",
      name: {
        de: "Entwicklungsumgebungen",
        en: "Development Environments"
      },
      priority: 2,
      children: [
        {
          source: "technologies",
          thingId: '3a28ef6b-77b6-44ad-978a-eaf662a2a703',
          priority: 1
        },
        {
          source: "technologies",
          thingId: "cffcf501-2c71-4b62-8b56-f64df920ed9f", /* Microsoft Visual Studio Code */
        }
      ]
    }
  ]
}
