export enum ReplacementType {
  String= "STRING",
  Word = "WORD",
  RepEx = "REG_EX"
}

export type Replacement = {
  searchValue: string | RegExp
  replacement: string
  type: ReplacementType
}
