import {IThings} from "../model/Things";
import {Language} from "../model/LanguageBundle";
import {ITreeRepresentationNode} from "../model/TreeGraph";
import {IRenderElementConfiguration, treeRepresentationNodeCreator} from "../model/ThingConverter";

export const azureTreeRepresentationNodeCreator = (language: Language, configuration?: IRenderElementConfiguration<ITreeRepresentationNode>): ITreeRepresentationNode => {
  const node = treeRepresentationNodeCreator(language, configuration);
  // Add extra vertical space for the node "Azure Enterprise Integration Platform as a Service".
  if (node.data.id === "3e6c6c62-189c-4c15-9da0-59a7a0cd4b6f") {
    node.properties.extraSpace = 20
  }
  // Add extra vertical space for the node "Microsoft Cloud Adoption Framework für Azure".
  if (node.data.id === "7a6bd934-b1df-4446-89f6-e7c566a7b212") {
    node.properties.extraSpace = 75
  }
  return node
}

export const azureKnowHow: IThings = {
  id: "e89cd544-b00b-4c02-a15a-5607bda61f2d",
  name: "Microsoft Azure",
  shortName: "Azure",
  priority: 2,
  children: [
    {
      thingId: "3e6c6c62-189c-4c15-9da0-59a7a0cd4b6f", /* Microsoft Cloud Adoption Framework für Azure */
      source: "technologiesAndPatterns"
    },
    {
      thingId: "7a6bd934-b1df-4446-89f6-e7c566a7b212", /* Azure Enterprise Integration Platform as a Service */
      source: "technologiesAndPatterns",
    },
    {
      thingId: "84971ac5-d0aa-4447-b7b4-652bf79abb55", /* Identity and access management */
      source: "technologiesAndPatterns",
      children: [
        {
          thingId: "c4fb6a94-23d3-46e7-a791-163edb6af9ec", /* Microsoft identity platform */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "45e85f59-d875-4d6f-914b-a28f8ad96051", /* Microsoft Entra */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "431ba733-b670-44e7-93fd-a3abbd5f8367", /* Azure Active Directory */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "6c104398-165d-4b51-bd9f-27bd476dcd7a", /* Azure Active Directory B2C */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "3d2c31fc-b096-41ec-9d59-dbb750dace58", /* Microsoft Authentication Library */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      thingId: "d12cb8f8-b3e0-428b-b846-7ba071112c7f", /* Azure API Management */
      source: "technologiesAndPatterns"
    },
    {
      id: "4b40cd13-d765-4439-93c5-615fe30972b4",
      name: "Azure App Service",
      aliases: ["Apps"],
      children: [
        {
          thingId: "30e143c6-9810-4495-b72f-e85b441f063e", /* Azure Web Apps */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "3870dfd2-75af-4026-8539-62adee0dea98", /* Azure Static Web Apps */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "d8f38ac3-afc7-4cd4-b111-1ff31c861ff1", /* Azure Functions */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "f4ac4c81-2258-4ade-89c9-ef74a214db49", /* Azure Durable Functions */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "0a6af6c6-0f02-4e21-a00b-328df23c2faf", /* Azure Container Apps */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "01a199ab-0600-4489-9205-16f420aee088",
      name: "Container",
      children: [
        {
          thingId: "76f24268-9ccb-4d63-98b6-dbeb05251f20", /* Azure Container Instances */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "5b2c6956-226d-4eed-9962-ec5b96256d77", /* Azure Container Registry */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "3f369ce9-948f-47ec-8515-6ba58c4076f9",
      name: "Integration",
      children: [
        {
          thingId: "340b0a7f-91c7-443c-9112-36b8c6848815", /* Azure Event Grid */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "57574a4d-4edf-46fc-8a3c-e5c811885906", /* Azure Service Bus */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "2e926952-ed44-4ec3-96a3-0671675a84a0", /* Microsoft Graph */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "bf00bf10-58bc-40cc-9704-83479bb7b79c",
      name: "Data",
      children: [
        {
          thingId: "b9b8ab71-ff03-490a-8d47-8d99d2216b97", /* Azure SQL Database */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "41f0309a-242b-4ee2-90df-bd90d3b11713", /* Azure Cosmos DB */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "baf1b380-9fa3-4bef-95b0-760f25c9de6e", /* Azure Storage account */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      thingId: "9c260564-c5fd-4e02-bf31-90abf6b199a8", /* Azure Monitor */
      source: "technologiesAndPatterns",
      children: [
        {
          thingId: "ad9939bf-06f4-43c7-a399-4cd9df847721",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "6136db96-5c55-437a-a253-71f6d47ef56f",
          source: "technologiesAndPatterns"
        },
      ]
    },
    {
      thingId: "c8409eae-9401-49da-8ab2-96752f1b71af", /* Azure DevOps Services */
      source: "technologiesAndPatterns",
      children: [
        {
          thingId: "85499e3f-6968-4050-b9d1-0a55af729995", /* Azure Pipelines */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "49d1b0d7-c9ba-49c7-9119-abf3415dc29f", /* Azure Repos */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "61dc691e-b221-481c-8a16-b88a004a4743", /* Azure Artifacts */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "66b4e952-44e0-4d24-8d5a-b675a36489d5",
      name: "Infrastructure as code",
      shortName: "IAC",
      children: [
        {
          thingId: "faba5689-d389-455a-92b8-6be3efef7e38", /* Azure Resource Manager */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "dbb2da6e-40ab-461a-b0c5-76b66709bda3", /* Azure Resource Manager Templates */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "a602416f-aff1-42e4-9ebb-ec4822bbfabf", /* Bicep */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "bca9bb2f-5b5e-41bb-b4aa-a075dea29743", /* Terraform */
          source: "technologiesAndPatterns"
        }
      ]
    },
    {
      id: "0042dc89-ee15-4d29-a92a-a20a07882a89",
      name: {
        de: "Konfiguration",
        en: "Configuration"
      },
      children: [
        {
          thingId: "fee15128-62d6-41fc-bc40-438872850267", /* Azure App Configuration */
          source: "technologiesAndPatterns"
        },
        {
          thingId: "02dc2c16-bb75-4f4c-a653-23e22b77e7d5", /* Azure Key Vault */
          source: "technologiesAndPatterns"
        }
      ]
    }
  ]
}
