import {IThings} from "../model/Things";
import {ILanguageBundle} from "../model/LanguageBundle";

export const domainDrivenDesign: IThings & { note: ILanguageBundle } = {
  id: "6599b6f5-2dff-4c76-92aa-00b5edb8dc06",
  name: "Domain Driven Design",
  shortName: "DDD",
  description: {
    de: "Übersicht über die Mustersprache",
    en: "Overview of the pattern language."
  },
  note: {
    de: "Original von Eric Evans, frei übersetzt von Bastian Nolte",
    en: " "
  }
}
