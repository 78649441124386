import {Replacement, ReplacementType} from "../model/IReplacement";

export const firstPersonToThirdPersonTranslations: Replacement[] = [
  // Satz(teil)ersetzungen
  {
    searchValue: "Mein Name ist [[name]]Bastian Nolte[[/name]]. \nIch",
    replacement: "Bastian Nolte",
    type: ReplacementType.String
  },
  {
    searchValue: "Ich sehe mich",
    replacement: "Bastian Nolte sieht sich",
    type: ReplacementType.String
  },
  {
    searchValue: "Ich war in den",
    replacement: "Bastian Nolte war in den",
    type: ReplacementType.String
  },
  {
    searchValue: new RegExp(/In der Projektarbeit nutzen wir ([^.]+)./),
    replacement: "In der Projektarbeit werden $1 genutzt.",
    type: ReplacementType.RepEx
  },
  {
    searchValue: "Ich verfüge über ",
    replacement: "Bastian Nolte verfügt über ",
    type: ReplacementType.String
  },
  {
    searchValue: "bilde ich mich ",
    replacement: "bildet Bastian Nolte sich ",
    type: ReplacementType.String
  },
  {
    searchValue: "meine Soft Skills",
    replacement: "die Soft Skills von Bastian Nolte",
    type: ReplacementType.String
  },
  {
    searchValue: "mein IT-Know-how",
    replacement: "das IT-Know-how von Bastian Nolte",
    type: ReplacementType.String
  },
  {
    searchValue: "mein bisheriges Tätigkeitsprofil",
    replacement: "das bisherige Tätigkeitsprofil von Bastian Nolte",
    type: ReplacementType.String
  },
  {
    searchValue: "ich die CSS",
    replacement: "Bastian Nolte die CSS",
    type: ReplacementType.String
  },
  {
    searchValue: "Weiterhin unterstützte ich ",
    replacement: "Weiterhin unterstützte Bastian Nolte ",
    type: ReplacementType.String
  },
  {
    searchValue: "war ich in verschiedenen Projekten ",
    replacement: "war Bastian Nolte in verschiedenen Projekten ",
    type: ReplacementType.String
  },
  {
    searchValue: "Ich bin Gründer ",
    replacement: "Bastian Nolte ist Gründer ",
    type: ReplacementType.String
  },
  {
    searchValue: "In Folge gehe ich auf einige Projekte des Unternehmens ein.",
    replacement: "In Folge wird auf einige Projekte des Unternehmens eingegangen.",
    type: ReplacementType.String
  },
  // Wortersetzungen
  {
    searchValue: "beherrsche",
    replacement: "beherrscht",
    type: ReplacementType.Word
  },
  {
    searchValue: "bilde",
    replacement: "bildet",
    type: ReplacementType.Word
  },
  {
    searchValue: "führe",
    replacement: "führt",
    type: ReplacementType.Word
  },
  {
    searchValue: "gehe",
    replacement: "geht",
    type: ReplacementType.Word
  },
  {
    searchValue: "habe",
    replacement: "hat",
    type: ReplacementType.Word
  },
  {
    searchValue: "ich",
    replacement: "er",
    type: ReplacementType.Word
  },
  {
    searchValue: "Ich",
    replacement: "Er",
    type: ReplacementType.Word
  },
  {
    searchValue: "mein",
    replacement: "sein",
    type: ReplacementType.Word
  },
  {
    searchValue: "Mein",
    replacement: "Sein",
    type: ReplacementType.Word
  },
  {
    searchValue: "meine",
    replacement: "seine",
    type: ReplacementType.Word
  },
  {
    searchValue: "Meine",
    replacement: "Seine",
    type: ReplacementType.Word
  },
  {
    searchValue: "meinem",
    replacement: "seinem",
    type: ReplacementType.Word
  },
  {
    searchValue: "Meinem",
    replacement: "Seinem",
    type: ReplacementType.Word
  },
  {
    searchValue: "meinen",
    replacement: "seinen",
    type: ReplacementType.Word
  },
  {
    searchValue: "Meinen",
    replacement: "Seinen",
    type: ReplacementType.Word
  },
  {
    searchValue: "meiner",
    replacement: "seiner",
    type: ReplacementType.Word
  },
  {
    searchValue: "Meiner",
    replacement: "Seiner",
    type: ReplacementType.Word
  },
  {
    searchValue: "meines",
    replacement: "seines",
    type: ReplacementType.Word
  },
  {
    searchValue: "Meines",
    replacement: "Seines",
    type: ReplacementType.Word
  },
  {
    searchValue: "mich",
    replacement: "sich",
    type: ReplacementType.Word
  },
  {
    searchValue: "mir",
    replacement: "ihm",
    type: ReplacementType.Word
  },
  {
    searchValue: "Mir",
    replacement: "Ihm",
    type: ReplacementType.Word
  },
  {
    searchValue: "nutze",
    replacement: "nutzt",
    type: ReplacementType.Word
  },
  {
    searchValue: "schreibe",
    replacement: "schreibt",
    type: ReplacementType.Word
  },
  {
    searchValue: "verfüge",
    replacement: "verfügt",
    type: ReplacementType.Word
  },
  {
    searchValue: "unterstütze",
    replacement: "unterstützt",
    type: ReplacementType.Word
  }
]
