import React, {FunctionComponent} from "react"
import * as styles from "./TabGroup.module.scss";
import nextId from "../idGenerator";

export const TabGroup: FunctionComponent<{ defaultTabIndex?: number }> = ({defaultTabIndex, children}) => {
  const uniqueId = nextId("tabgroup")
  const selectorId = (index: number) => `${uniqueId}-tab${index}-selector`
  const childrenArray = React.Children.toArray(children)
  const tabHeaders = childrenArray.filter(child => (child as React.ReactElement<{ name: string }>).type === TabHeader)
  const tabViews = childrenArray.filter(child => (child as React.ReactElement<{ name: string }>).type === TabView)

  return (
    <div className={styles.tabbed}>
      {
        tabHeaders.map((_, idx) =>
          <input
            key={idx}
            defaultChecked={idx === (defaultTabIndex || 0)}
            id={`${selectorId(idx)}`}
            type="radio"
            name={uniqueId}/>
        )
      }
      <nav>
        {
          tabHeaders.map((tabheader, idx) =>
            <label key={idx} htmlFor={`${selectorId(idx)}`}>{tabheader}</label>
          )
        }
      </nav>

      <div className={styles.tabViewsContainer}>
        {
          tabViews.map((tabview, idx) =>
            <div key={idx}>{tabview}</div>
          )
        }
      </div>
    </div>
  )
}

export const TabHeader: FunctionComponent = ({children}) => <>{children}</>
export const TabView: FunctionComponent = ({children}) => <>{children}</>
