import React from "react";
import {Section} from "../Section";
import {replaceParagraphsWithPTags, replaceSquareBracketsWithSpanTags} from "../../textToHtmlSubstitution";
import {abstract} from "../../data/Abstract";
import {PersonType} from "../../model/PersonType";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {t, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Abstract = ({personType, language}: { personType: PersonType, language: Language }) => {
  const title = t(abstract.header, language)
  const sectionId = toId(title)

  return (
    <Section id={sectionId} title={title} navigation avoidPageBreak language={language}>
      <div dangerouslySetInnerHTML={{
        __html: replaceSquareBracketsWithSpanTags(
          replaceParagraphsWithPTags(
            reshapeTheTextAsNeeded(t(abstract.content, language), personType)
          )
        )
      }}/>
    </Section>
  )
}
