import React from "react";
import {Section} from "../Section";
import * as styles from "./Preferences.module.scss"
import {preferences} from "../../data/Preferences";
import {IPreference, IPreferenceGroup} from "../../model/Preferences";
import {localizer, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Preferences = ({language}: {language: Language}) => {
  const t = localizer(language)
  return (
    <Section id={toId(t(preferences.header))} title={t(preferences.header)} subtitle={t(preferences.subHeader)} navigation avoidPageBreak language={language}>
      <div className={styles.preferences}>
        {preferences.preferences.flatMap((preferenceOrGroup, idx) => {
          if ((preferenceOrGroup as IPreference).text) {
            const preference = preferenceOrGroup as IPreference
            return (
              <React.Fragment key={idx}>
                <h3>{t(preference.title)}</h3>
                <p>{t(preference.text)}</p>
              </React.Fragment>
            )
          } else {
            const preferenceGroup = preferenceOrGroup as IPreferenceGroup
            return (
              <React.Fragment key={idx}>
                <h3>{t(preferenceGroup.title)}</h3>
                <ul>
                  {preferenceGroup.preferences.map((preference, idx) => <li key={idx}>{t(preference.title)}: {t(preference.text)}</li>)}
                </ul>
              </React.Fragment>
            )
          }
        })}
      </div>
    </Section>
  )
}
