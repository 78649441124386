import React, {FunctionComponent} from "react";
import {Section} from "../Section";
import {Depth, TreeGraph, TreeGraphConfiguration, Width} from "../TreeGraph";
import {developmentKnowHow} from "../../data/DevelopmentKnowHow";
import {javaScalaVmKnowHow} from "../../data/JavaScalaVmKnowHow";
import {toolsetKnowHow} from "../../data/ToolsetKnowHow";
import {IRenderElementConfiguration, ThingConverter} from "../../model/ThingConverter";
import {TabGroup, TabHeader, TabView} from "../TabGroup";
import {UnorderedTreeList} from "../UnorderedTreeList";
import {ITreeRepresentationNode} from "../../model/TreeGraph";
import {DddKnowHow} from "./DddKnowHow";
import {designPatternKnowHow} from "../../data/DesignPatternKnowHow";
import {knowHow} from "../../data/KnowHow";
import {unixKnowHow} from "../../data/UnixKnowHow";
import {PersonType} from "../../model/PersonType";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {localizer, toId} from "../../i18n";
import {IThings} from "../../model/Things";
import {Language} from "../../model/LanguageBundle";
import {azureKnowHow, azureTreeRepresentationNodeCreator} from "../../data/Azure";

export const KnowHow = ({personType, language}: { personType: PersonType, language: Language }) => {
  const t = localizer(language)

  /*
   * Creates a Know-how Section based on the passed IThing instance.
   */
  const createKnowHowSection = (thing: IThings, depthWiths: Map<Depth, Width>, customConverter?: (language: Language, configuration?: IRenderElementConfiguration<ITreeRepresentationNode>) => ITreeRepresentationNode) => {
    const converter = customConverter
      ? new ThingConverter(language, customConverter)
      : ThingConverter.createToTreeRepresentationConverter(language)
    return (
      <Section id={toId(t(thing.shortName || thing.name))} title={t(thing.name)} navigation avoidPageBreak language={language}>
        <KnowHowTabGroup
          rootNode={converter.convert(thing)}
          configuration={{
            depthWidths: depthWiths
          }}
          language={language}
        />
      </Section>
    )
  }

  return (
    <>
      <Section id={toId(t(knowHow.header))} title={t(knowHow.header)} navigation avoidPageBreak language={language}>
        <div className="alternative">
          <p>{reshapeTheTextAsNeeded(t(knowHow.graphDescription), personType)}</p>
        </div>
      </Section>

      {/* Know-how Sections */}
      {/* Development */ createKnowHowSection(developmentKnowHow, new Map().set(0, 200).set(1, 190).set(2, 200).set(3, 365))}
      {/* Azure */ createKnowHowSection(azureKnowHow, new Map().set(0, 230).set(1, 190).set(2, 390), azureTreeRepresentationNodeCreator)}
      {/* Java, JVM */ createKnowHowSection(javaScalaVmKnowHow, new Map().set(0, 200).set(1, 220).set(3, 350))}
      {/* Domain Driven Design */}<DddKnowHow language={language}/>
      {/* Werkzeuge / Tools */ createKnowHowSection(toolsetKnowHow, new Map().set(0, 280).set(1, 170).set(2, 680))}
      {/* Design-Pattern */ createKnowHowSection(designPatternKnowHow, new Map().set(1, 150).set(2, 505))}
      {/* Unix-Know-how */ createKnowHowSection(unixKnowHow, new Map().set(0, 150).set(1, 250).set(2, 605))}
    </>
  )
}

export const KnowHowTabGroup: FunctionComponent<{ rootNode: ITreeRepresentationNode, configuration?: TreeGraphConfiguration, language: Language }> =
  ({rootNode, configuration, language}) => {
    const t = localizer(language)
    return (
      <TabGroup>
        <TabHeader>Graph</TabHeader>
        <TabView>
          <TreeGraph rootNode={rootNode} configuration={configuration} language={language}/>
        </TabView>
        <TabHeader>{t({de: "Listenansicht", en: "List view"})}</TabHeader>
        <TabView>
          <UnorderedTreeList node={rootNode}/>
        </TabView>
      </TabGroup>
    )
  }
