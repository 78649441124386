import {IThings} from "../model/Things";

export const designPatternKnowHow: IThings = {
  id: "09fc5eee-5447-4866-913e-5cf83aa94e95",
  name: {
    de: "Design Pattern & Prinzipien",
    en: "Design Patterns & Principles"
  },
  shortName: {
    de: "Design Pattern",
    en: "Design Patterns"
  },
  children: [
    {
      thingId: "6d6e71ba-dfd7-446b-bcbe-55e17a43ebe4",
      source: "pattern",
      children: [
        {
          thingId: "3764086A-F62D-4EA0-9AAB-6C39969DA640",
          source: "pattern"
        },
        {
          thingId: "F1C12574-D210-46C5-8444-E489EAE6B763",
          source: "pattern"
        },
        {
          thingId: "A648A0C1-90F3-4015-9229-272745B0021A",
          source: "pattern"
        },
        {
          thingId: "C93E2468-5805-4575-B43E-E67AFB2A7C71",
          source: "pattern"
        },
        {
          thingId: "23E7C08E-950F-4446-B44D-DB31D6BD9E8C",
          source: "pattern"
        },
        {
          thingId: "6022CA27-6BFE-421A-96A0-FF6C33E6D7BB",
          source: "pattern"
        },
        {
          thingId: "C2E11C4C-9574-4183-BAD0-5144716B603A",
          source: "pattern"
        },
        {
          thingId: "F673BA7B-F063-4592-A334-D58434007C70",
          source: "pattern"
        },
        {
          thingId: "b02427c8-5acb-4f64-88fd-d29c1ff94a3c",
          source: "pattern"
        }
      ]
    },
    {
      id: "824faae7-261d-4ad4-8a58-208e29103e51",
      name: "Analysis pattern",
      aliases: {
        de: ["Analysemuster"],
        en: []
      },
      wikiUrl: "https://de.wikipedia.org/wiki/Analysemuster",
      children: [
        {
          thingId: "35aea2be-fb33-406e-829e-9858459bfcc1",
          source: "pattern"
        },
        {
          thingId: "E0804202-DA35-4828-8B25-32E6AA47697A",
          source: "pattern"
        }
      ]
    },
    {
      id: "8c9912ad-2df8-495f-b856-f66a83a2855a",
      name: "Creational design pattern",
      aliases: {
        de: ["Erzeugungsmuster"],
        en: []
      },
      children: [
        {
          thingId: "B6F20C87-5908-4EF2-BEDD-39BABABDB527",
          source: "pattern"
        },
        {
          thingId: "DEA9758E-AF2D-48EC-A320-4ED919066419",
          source: "pattern"
        },
        {
          thingId: "037DE745-3A10-4E47-B1A1-8B3769979829",
          source: "pattern"
        },
        {
          thingId: "DF076B52-CD91-46F8-B6E1-D4D065DE08A9",
          source: "pattern"
        }
      ]
    },
    {
      id: "14ebb89e-72ad-4d4f-bf7d-4c572fb975cf",
      name: "Structural Design Pattern",
      aliases: {
        de: ["Strukturmuster"],
        en: []
      },
      children: [
        {
          thingId: "E105D3F7-76B3-4E7C-B040-DB2A9F56CE9F",
          source: "pattern"
        },
        {
          thingId: "F0BA221E-8DC9-47F3-A809-CDE9FEB4BE2B",
          source: "pattern"
        },
        {
          thingId: "91EB99C2-97A2-4DD5-A3C8-F70579922D82",
          source: "pattern"
        },
        {
          thingId: "27AA01AE-62C9-4990-8672-0BD91684047F",
          source: "pattern"
        },
        {
          thingId: "F09FFD9D-DB0F-4D0F-ACFE-BEE21FC658C3",
          source: "pattern"
        },
        {
          thingId: "4A51EE36-997B-443A-AEA2-8301282CD567",
          source: "pattern"
        },
        {
          thingId: "847313EA-D985-4953-82E2-10029D11CE3D",
          source: "pattern"
        },
        {
          thingId: "9DB0BFE0-C2E9-4D57-9AEE-B8F16C0D0147",
          source: "pattern"
        }
      ]
    },
    {
      id: "a5039343-97fb-484b-8666-db17d8d4c38f",
      name: "Behavioral Design Pattern",
      aliases: {
        de: ["Verhaltensmuster"],
        en: []
      },
      children: [
        {
          thingId: "8A74507D-A8CC-43DF-A117-FA9B5345304B",
          source: "pattern"
        },
        {
          thingId: "B028FE34-2D47-4769-BBBC-82C1FA64A894",
          source: "pattern"
        },
        {
          thingId: "7A8FA5AF-E044-4778-B135-A29BA20F6A6F",
          source: "pattern"
        },
        {
          thingId: "C6339454-50D0-4F75-BBA6-9B0E135C36BB",
          source: "pattern"
        },
        {
          thingId: "8CDCA296-96E8-48E4-ADE8-6EAAF4E425A0",
          source: "pattern"
        },
        {
          thingId: "43E5A46F-89D6-4E77-B772-3B278854F1C2",
          source: "pattern"
        },
        {
          thingId: "34871205-FCC2-4350-B293-F8AC7A7C3FC4",
          source: "pattern"
        }
      ]
    },
    {
      id: "1e6aa8c1-a13f-4907-a388-4d5da3016029",
      name: "Concurrency pattern",
      aliases: {
        de: ["Nebenläufigkeitsmuster"],
        en: []
      },
      children: [
        {
          thingId: "C681280C-8F4E-4A3F-BB9A-F897DD2651C8",
          source: "pattern"
        },
        {
          thingId: "DC0BF223-A62F-48E3-B234-633BBEE3C080",
          source: "pattern"
        },
        {
          thingId: "84832B62-8A09-4C0B-AFB4-089376EDB11D",
          source: "pattern"
        }
      ]
    },
    {
      id: "2f630ca3-87bc-4a34-8453-94f823d340da",
      name: "SOLID principles",
      children: [
        {
          thingId: "EC307B31-BBFE-46E6-B050-E13B67C711D5",
          source: "pattern"
        },
        {
          thingId: "3F8AEB02-CED7-40B3-8FF7-D9F91D9A5883",
          source: "pattern"
        },
        {
          thingId: "A0F7E5BC-0ACA-41CE-A6D3-4164464DA081",
          source: "pattern"
        },
        {
          thingId: "E4DC0F52-2A00-463D-A669-64FF3810BA66",
          source: "pattern"
        },
        {
          thingId: "9A9D2334-53B6-4D12-9D77-401AE08563E6",
          source: "pattern"
        }
      ]
    },
    {
      id: "5bc30724-b1d1-499e-9719-942e51e9e42d",
      name: "Distributed computing",
      aliases: {
        de: ["Verteilte Systeme"],
        en: []
      },
      children: [
        {
          thingId: "e3d261e1-7fae-4815-a2b9-8ab5b4012f34",
          source: "pattern"
        },
        {
          thingId: "cd117411-b45a-4976-8b0f-c3a645f86f69",
          source: "pattern"
        },
        {
          thingId: "f57612f2-68f7-47ef-93b0-70dd4da63e09",
          source: "pattern"
        }
      ]
    },
    {
      id: "3d8583d8-3a94-4b2d-987c-916a1c06abf4",
      name: "Sharding Pattern",
      children: [
        {
          thingId: "124D9730-B466-40A0-9368-5EF6610EFF90",
          source: "pattern"
        },
        {
          thingId: "FAD7E387-7BE5-4CC4-871B-6E0E27C2AAAC",
          source: "pattern"
        },
        {
          thingId: "860C15F7-8A1C-48DC-84B2-A0DA370F16E2",
          source: "pattern"
        },
      ]
    }
  ]
}
