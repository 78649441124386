import React from "react";
import {v4 as uuid} from 'uuid';

interface CircleProps {
  text: string
}

export const Circle: React.FunctionComponent<CircleProps> = (probs) => {
  const filterId = `filter-${uuid()}`

  return (
    <svg
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id={filterId} x="-1%" y="-1%" width="110%" height="110%">
          <feOffset result="offOut" in="SourceGraphic" dx="3" dy="3"/>
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"/>
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
        </filter>
      </defs>
      <circle cx="100" cy="100" r="90" fill="#4b85ce" stroke="orange" strokeWidth="2px" filter={`url(#${filterId})`}/>
      <text textAnchor="middle" dominantBaseline="middle" fontSize="2em" fill="rgba(250, 250, 248, 0.9)">
        <tspan x="100" dy="100">{probs.text}</tspan>
      </text>
    </svg>
  )
}
