import {IThings} from "../model/Things";

export const javaScalaVmKnowHow: IThings = {
  id: "e89cd544-b00b-4c02-a15a-5607bda61f2d",
  name: {
    de: "Java, Scala und JVM (im Detail)",
    en: "Java, Scala und JVM (in detail)",
  },
  shortName: "Java / JVM",
  priority: 2,
  children: [
    {
      id: "a9d4204d-ad3d-4d85-8a9d-965eafdedb72",
      name: "JavaSE",
      priority: 2,
      children: [
        {
          thingId: "6a4d72e1-4355-4f86-ac41-75ec0f4047be",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "60bd4527-a606-4180-8261-8f8d6fff0412",
          source: "technologiesAndPatterns"
        },
        {
          id: "3a9e0a0a-e701-4c71-a243-fe447bbfbee3",
          name: "API / Libraries",
          priority: 2,
          children: [
            {
              thingId: "86e17b4a-73c3-4f25-9ce6-155dc45e8e0c",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "577974c6-b5d2-4e48-8c7d-5ef9ed18ffb4",
              source: "technologiesAndPatterns",
              priority: 1
            },
            {
              thingId: "e5e1e5f3-b307-425e-888d-bf83ede9c146",
              source: "technologiesAndPatterns",
            },
            {
              thingId: "90c1f8b3-668f-447f-8515-1d8fbec66741",
              source: "technologiesAndPatterns",
            },
            {
              thingId: "7edf1ac7-631b-4bd3-a036-e84aefc2a393",
              source: "technologiesAndPatterns",
            },
            {
              thingId: "c3a64cef-fd6e-48f0-b82e-20f90ad6c103",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "834e9fe3-6f03-40e7-a7a5-46fd668a28b9",
              source: "technologiesAndPatterns",
            },
            {
              thingId: "39bdefbf-fb38-42ed-ac94-ce37e3e893d9",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "b1b491e4-1900-4d88-9b2b-cbd0e8439365",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "a58e5cbc-9a47-442d-ae6f-bfb8e66b902e",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "217e5c5a-e668-48cf-8cf7-92720a0bb4bc",
              source: "technologiesAndPatterns",
              priority: 1,
            },
            {
              thingId: "3f40934b-22a2-4879-a003-daeb442db924",
              source: "technologiesAndPatterns",
            },
            {
              thingId: "0c2f88f1-ae4c-4662-834f-d632d4c7df9c",
              source: "technologiesAndPatterns"
            }
          ]
        },
        {
          thingId: "785764c6-2d4b-4c7b-82fd-c8da9b9f5f72",
          source: "technologiesAndPatterns"
        },
        {
          thingId: "fa5bb0c2-f73c-4656-a27b-6f903680d977",
          source: "technologiesAndPatterns",
        }
      ]
    },
    {
      id: "5301af49-7b0b-4c59-81d8-95710c91f7dc",
      name: "Java EE / Jakarta EE",
      priority: 2,
      children: [
        {
          id: "8f8aa94a-bbc6-43a4-ac04-be938483a899",
          name: "Frameworks / API",
          priority: 2,
          children: [
            {
              thingId: "406f8ef4-2801-46a0-9bac-a2765d3694d8",
              source: "technologiesAndProducts"
            },
            {
              thingId: "3981e884-8a5b-4b1b-b076-e8ee38b78e31",
              source: "technologiesAndProducts"
            },
            {
              thingId: "0a9114d5-c5bd-47c0-8533-a27fa228a2f1",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "7b834e25-d7c1-447f-bb25-2262812fe089",
              source: "technologiesAndProducts"
            }
          ]
        },
        {
          id: "436439fc-36fa-4b9d-a487-13e635a813ba",
          name: "Management and Security Technologies",
          priority: 2,
          children: [
            {
              thingId: "d4966824-7feb-4761-965a-4ad31fa1f5f7",
              source: "technologiesAndProducts",
              priority: 1,
            }
          ]
        },
        {
          id: "573476a7-90a7-402d-b6d5-7a2e8c48ab57",
          name: "Web Application Technologies",
          priority: 2,
          children: [
            {
              thingId: "5b6ec6c2-0317-4865-9c08-0f36d5d96bdc",
              source: "technologiesAndProducts"
            },
            {
              thingId: "540f8a19-888e-4a78-993c-cad0d67c8a34",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "80046f4e-e9f0-4ce8-b47f-6acd517cde09",
              source: "technologiesAndProducts"
            },
            {
              thingId: "7a52c9dc-2680-4295-a278-0e3283071b98",
              source: "technologiesAndProducts"
            },
            {
              thingId: "effc8353-d177-4981-93f2-87453bf37953",
              source: "technologiesAndProducts"
            },
            {
              thingId: "4bbc1cef-0ddf-4a37-a8e2-2827cf185918",
              source: "technologiesAndProducts"
            }
          ]
        },
        {
          id: "f79a5714-088c-4f02-9d71-1178e94c38bb",
          name: "Web Services Technologies",
          priority: 2,
          children: [
            {
              thingId: "c688ac10-b1ea-4f32-9f56-146e00c3e558",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "6a70dca7-c8ef-44ae-a8ac-7a2972a27aff",
              source: "technologiesAndProducts"
            },
            {
              thingId: "6a7e93ff-b879-487f-91f2-132d8d4a8d93",
              source: "technologiesAndPatterns"
            }
          ]
        },
        {
          id: "62cf60a0-d38c-4e10-8c49-5e524fbddff6",
          name: "Enterprise Application Technologies",
          priority: 2,
          children: [
            {
              thingId: "a0724ef2-8ea0-439a-9c94-5cb4b3119486",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "310c2a1f-c86f-4971-a82c-08ce5335a13a",
              source: "technologiesAndProducts"
            },
            {
              thingId: "42bc64d4-b834-4f48-88ca-d885b4cf6f10",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "404a8e13-3dc5-41ad-9158-6f5ebc44005d",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "ae5a6737-be21-4b88-9e5b-8b5f85538060",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "79c6fb8d-7446-49c9-8621-10b66e29ee4f",
              source: "technologiesAndProducts"
            },
            {
              thingId: "f14fadfe-9dcc-4d36-8799-d49d21a7bbd7",
              source: "technologiesAndProducts"
            }
          ]
        },
        {
          id: "e4aa2bb5-48f4-4018-994d-53e5e95d3845",
          name: "(Application) Server",
          priority: 2,
          children: [
            {
              thingId: "ed2de5ce-a60d-4d99-8ad1-02a75985b537",
              source: "technologiesAndPatterns"
            },
            {
              thingId: "ce9b2fbc-33b8-4d5e-a0d5-4c310498e269",
              source: "technologiesAndProducts"
            },
            {
              thingId: "02cfabc7-4b18-4122-abac-fe0310d5bee1",
              source: "technologiesAndProducts"
            },
            {
              thingId: "ab8c6ac7-de2c-4b1d-8a85-4272a2d531b2",
              source: "technologiesAndProducts"
            },
            {
              thingId: "bba3532d-5410-4ce0-9622-c4f913321669",
              source: "technologiesAndProducts"
            },
            {
              thingId: "2282a694-1883-49c7-8156-330fc2e1daf3",
              source: "technologiesAndProducts",
              priority: 1,
            }
          ]
        }
      ]
    },
    {
      id: "9a11f347-43c8-40e3-8389-6a7c36a43805",
      name: "Eclipse MicroProfile",
      priority: 1,
      children: [
        {
          id: "f94ec1c4-c49d-4316-a61d-c3912870850b",
          name: {
            de: "Technologien",
            en: "Technologies"
          },
          priority: 2,
          children: [
            {
              thingId: "e2d543cb-1dbd-42f8-92f3-b5ad2b2498e6",
              source: "technologiesAndProducts",
            },
            {
              thingId: "d3313314-2bd5-4e3b-81e4-7f4bbf3dea22",
              source: "technologiesAndProducts",
            },
            {
              thingId: "1058757c-0fc2-445e-82da-75e45175b7d5",
              source: "technologiesAndProducts",
            },
            {
              thingId: "cdc5f954-4440-4d9f-881c-9bc4dc6c48df",
              source: "technologiesAndProducts",
            },
            {
              thingId: "f668a2ca-0606-4d92-b850-851a04a71036",
              source: "technologiesAndProducts",
            },
            {
              thingId: "dedfa1cc-106e-435a-a758-03842ed6f34d",
              source: "technologiesAndProducts",
            }
          ]
        }
      ]
    },
    {
      id: "48be9529-b1ca-4d64-8321-c1abe77b10f8",
      name: "Scala",
      priority: 2,
      children: [
        {
          id: "121c19c9-b0fb-4eb0-8100-0a09ec8d3100",
          name: "Frameworks",
          priority: 2,
          children: [
            {
              thingId: "94d709d6-0ae2-4dd2-928f-b1cb0b498c16",
              source: "technologiesAndProducts",
              priority: 1,
            },
            {
              thingId: "a34141c0-de0f-4937-a777-c2367ea3b30c",
              source: "technologiesAndProducts",
            }
          ]
        }
      ]
    }
  ]
}
