import React, {FunctionComponent} from "react";
import {IProjectDescription, IProjectGroup, TProjects} from "../../model/Project";
import * as styles from "./Projects.module.scss";
import {Section} from "../Section";
import {TechnologiesAndProducts} from "./TechnologiesAndProducts";
import {PersonType} from "../../model/PersonType";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {localizer} from "../../i18n";
import {Language} from "../../model/LanguageBundle";
import {projectHeader} from "../../data/Projects";

const paragraphs = require('lines-to-paragraphs');

type ProjectsProbs = {
  projects: TProjects
  personType: PersonType
  language: Language
}

export const Projects: FunctionComponent<ProjectsProbs> = ({projects, personType, language, children}) => {
  const t = localizer(language)
  return (
    <>
      {
        projects.map((curProject, idx) => {
          if ((curProject as IProjectGroup).projects) {
            const projectGroup = (curProject as IProjectGroup)

            return (
              <React.Fragment key={idx}>
                <Section
                  id={`project-${projectGroup.id}`}
                  key={idx}
                  title={t(projectGroup.title)}
                  subtitle={t(projectGroup.subtitle)}
                  navigation
                  language={language}
                  customClasses={!projectGroup.filterMatch ? 'hidden' : ''}
                >
                  {(projectGroup.description)
                    ? <div className={`${styles.description} alternative`} dangerouslySetInnerHTML={{__html: paragraphs(reshapeTheTextAsNeeded(t(projectGroup.description), personType))}} />
                    : ''}
                  {(children) && children}
                </Section>
                <Projects projects={projectGroup.projects} personType={personType} language={language}/>
              </React.Fragment>
            )
          } else {
            const project = (curProject as IProjectDescription)
            return (
              <Section
                id={`project-${project.id}`}
                key={idx}
                title={t(project.title)}
                subtitle={t(project.subtitle)}
                navigation
                language={language}
                customClasses={!project.filterMatch ? 'hidden' : ''}
              >
                {(t(project.description))
                  ? <div className={`${styles.description} alternative`} dangerouslySetInnerHTML={{__html: paragraphs(reshapeTheTextAsNeeded(t(project.description), personType))}}/>
                  : null}
                <div className={styles.grid}>
                  <div className={styles.rowHeader}>{t(projectHeader.role)}</div>
                  <div>{t(project.role)}</div>
                  <div className={styles.rowHeader}>{t(projectHeader.period)}</div>
                  {(project.period)
                    ? <div>{project.period.getPeriodInformation(language)}{(project.period.note) ? <div className={styles.periodDetails}>({t(project.period.note)})</div> : null}</div>
                    : null}
                  <div className={styles.rowHeader}>{t(projectHeader.activities)}</div>
                  <div>
                    <ul>{t(project.activities)?.map((activity, index) => <li key={index}>{activity}</li>)}</ul>
                  </div>
                  <TechnologiesAndProducts tnp={project.technologiesAndProducts} language={language}/>
                </div>
              </Section>
            )
          }
        })
      }
    </>
  )
}
