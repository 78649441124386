import {IContact} from "../model/Contact";
import ContactImage from "../assets/contact.svg"
import ContactImageEn from "../assets/contact_en.svg"
import XingImage from "../assets/xing.svg"
import LinkedInImage from "../assets/linkedin.svg"
import GulpImage from "../assets/gulp_bl_160x30px_fmb_rot.jpg"

export const contactDetails: IContact = {
  header: {
    de: "Kontakt",
    en: "Contact details"
  },
  introduction: {
    de: "Für Projektanfragen bitte ich um Kontaktaufnahme via E-Mail oder über eines meiner Profile (z.B. bei Xing).",
    en: "For project enquiries, please contact me by email or via one of my profiles (e.g. on Xing)."
  },
  contactInformationImage: {
    de: ContactImage,
    en: ContactImageEn
  },
  contactInformationImageAlt: {
    de: "Weiterführende Kontaktinformationen von Bastian Nolte",
    en: "Further contact information of Bastian Nolte"
  },
  profilePages: [
    {
      badge: XingImage,
      url: "https://www.xing.com/profile/Bastian_Nolte",
      alternativeText: {
        de: "Logo von Xing",
        en: "Logo of the company Xing"
      }
    },
    {
      badge: LinkedInImage,
      alternativeText: {
        de: "Logo von LinkedIn",
        en: "Logo of the company LinkedIn"
      },
      url: "https://www.linkedin.com/in/bastian-nolte"
    },
    {
      badge: GulpImage,
      alternativeText: {
        de: "Logo von Gulp",
        en: "Logo of the company  Gulp"
      },
      url: "https://www.gulp.ch/gulp2/home/profil/bastian-nolte"
    }
  ]
}
