import {IHeader} from "model/Header";

export const header: IHeader = {
  level: {
    de: "Experte",
    en: "Expert"
  },
  subHeader: {
    de: "Digitales Curriculum Vitae / Profil",
    en: "Digital Curriculum Vitae / Profile"
  },
  jobHeader1: {
    de: "<span className=\"nowrap\">IT Business Consultant</span> & Trainer",
    en: "<span className=\"nowrap\">IT Business Consultant</span> & Trainer",
  },
  jobHeader2: {
    de: "Software-Architekt & <span className=\"nowrap\">Full Stack Developer</span>",
    en: "Software Architect & <span className=\"nowrap\">Full Stack Developer</span>"
  },
  alternativeTexts: {
    photo: {
      de: "Foto von Bastian Nolte",
      en: "Photo of Bastian Nolte"
    },
    vectorGraphic: {
      de: "Vektorgrafik mit Notebook, Smartphone, Notizbuch und den Gedankenblasen, «Azure», «Kotlin», «MicroServices» und «vue.js»",
      en: "Vektor graphic with notebook, smartphone, notebook und den mind bubbles, «Azure», «Kotlin», «MicroServices» and «vue.js»"
    }
  }
}
