import React, {useEffect, useState} from 'react'
import {InformationBox} from "./InformationBox"
import {ILanguageBundle, ILanguageBundleArray, Language} from "../model/LanguageBundle";
import {localizer} from "../i18n";

const styles = require('./BadExperienceDetect.module.scss')

type Infobox = {
  header: ILanguageBundle
  texts: ILanguageBundleArray
}

const info: Infobox = {
  header: {
    de: "Das Layout dieser Webseite kann in Ihrem Brower nicht korrekt angezeigt werden.",
    en: "The layout of this website may not be displayed correctly in your browser."
  },
  texts: {
    de: [
      "Mit einem aktuellen Browser haben sie wahrscheinlich ein besseres Benutzererlebnis im Web.",
      "Unter anderem die folgende Browser unterstützen aktuelle Webtechnologien…"
    ],
    en: [
      "With an up-to-date browser, you will probably have a better user experience on the web.",
      "Among others, the following browsers support current web technologies…"
    ]
  }
}
export const BadExperienceDetect = ({language}: { language: Language }) => {
  const [supports, setSupports] = useState(true);
  const t = localizer(language)

  /* Check for support of display: flex */
  const checkFlexSupport = () => {
    let supports: boolean
    try {
      supports = CSS.supports("display", "flex")
    } catch (err) {
      supports = false
    }
    return supports
  }

  useEffect(() => {
    setSupports(checkFlexSupport())
  }, [])

  return (
    <aside id="bad-experience-banner" data-nosnippet className={styles.hide} style={supports ? {display: 'none'} : {display: 'block'}}>
      <InformationBox styles={{borderColor: 'red'}}>
        <h1>{t(info.header)}</h1>
        <div>
          <p>{t(info.texts)[0]}</p>{t(info.texts)[1]}<br/>
          <a href="https://www.google.com/intl/de/chrome/" rel="noopener noreferrer" target="_blank">Google Chrome</a>,&nbsp;
          <a href="https://www.mozilla.org/de/firefox/" rel="noopener noreferrer" target="_blank">Mozilla Firefox</a>,&nbsp;
          <a href="https://www.microsoft.com/de-de/edge" rel="noopener noreferrer" target="_blank">Microsoft Edge</a>,&nbsp;
          <a href="https://www.opera.com/de" rel="noopener noreferrer" target="_blank">Opera</a>,&nbsp;
          <a href="https://www.apple.com/safari/" rel="noopener noreferrer" target="_blank">Safari (MacOS, iOS, iPadOS)</a>
        </div>
      </InformationBox>
    </aside>
  )
}
