import {IThing, IThings, thingsTypeGuards, TLinkOrThing} from "./Things";
import {ICellRepresentationNode, ITreeRepresentationNode} from "./TreeGraph";
import {technologiesAndPatterns} from "../data/TechnologiesAndPatterns";
import {maskControlCharacters} from "../latexTemplates/Replacements";
import {localizer} from "../i18n";
import {Language} from "./LanguageBundle";

export interface IRenderElementConfiguration<E> {
  thing?: IThing,
  children?: Array<E>,
  priority?: number
  depth: number
}

export class ThingConverter<E> {
  private readonly language: Language
  private readonly createNode: (language: Language, configuration?: IRenderElementConfiguration<E>) => E

  static createToTreeRepresentationConverter(language: Language) {
    return new ThingConverter(language, treeRepresentationNodeCreator)
  }

  static createToTableRepresentationConverter(language: Language) {
    return new ThingConverter(language, tableRepresentationCellCreator)
  }

  constructor(language: Language, creator: (language: Language, configuration?: IRenderElementConfiguration<E>) => E) {
    this.language = language
    this.createNode = creator
  }

  convert(thing: TLinkOrThing, depth: number = 0): E {
    const loadThingIfNecessary = (thing: TLinkOrThing) => {
      if (thingsTypeGuards.isLink(thing)) {
        const concreteThing = Object.assign({}, technologiesAndPatterns.find(entry => entry.id === thing.thingId)) // Copy object (warning: no deep copy).
        if (concreteThing && thingsTypeGuards.isThings(thing)) {
          (concreteThing as IThings).children = thing.children
        }
        return concreteThing
      } else {
        return Object.assign({}, thing) // Copy object (warning: no deep copy).
      }
    }

    const concreteThing = loadThingIfNecessary(thing)

    const children: E[] = thingsTypeGuards.isThings(concreteThing)
      ? concreteThing.children?.map(currentThing => this.convert(currentThing, depth + 1)) || []
      : []

    return this.createNode(this.language, {depth, thing: concreteThing, children, priority: thing.priority})
  }
}

export const tableRepresentationCellCreator = (language: Language, configuration?: IRenderElementConfiguration<ICellRepresentationNode>): ICellRepresentationNode => {
  const t = localizer(language)
  if (configuration?.thing === undefined) {
    return {
      data: {
        id: "undefined",
        name: "Daten zurzeit nicht verfügbar!"
      },
      properties: {
        color: "#ff5207",
        rowSpan: 1
      },
      children: []
    }
  }

  const color = configuration.priority ? configuration.priority === 1 ? "lightsalmon" : "peachpuff" : "none"
  const thing = configuration.thing
  const aliases: string[] = thingsTypeGuards.isStringArray(thing.aliases) ? thing.aliases : t(thing.aliases)
  return {
    data: {
      ...thing,
      name: maskControlCharacters(t(thing.name)),
      shortName: maskControlCharacters(t(thing.shortName || "")),
      aliases: aliases?.map(alias => maskControlCharacters(t(alias))),
      description: thing.description !== undefined ? maskControlCharacters(t(thing.description)) : undefined
    },
    properties: {
      color: color,
      rowSpan: configuration.children?.reduce((summary, currentCell) => summary + currentCell.properties.rowSpan, 0) || 1
    },
    children: configuration.children || []
  }
}

export const treeRepresentationNodeCreator = (language: Language, configuration?: IRenderElementConfiguration<ITreeRepresentationNode>): ITreeRepresentationNode => {
  const t = localizer(language)
  if (configuration?.thing === undefined) {
    return {
      data: {
        id: "undefined",
        name: "Daten zurzeit nicht verfügbar!"
      },
      properties: {
        color: "#ff5207",
        depth: 0,
        radius: calculateCircleRadius(0),
        extraSpace: 0
      },
      children: []
    }
  }

  const color = configuration.priority ? configuration.priority === 1 ? "lightsalmon" : "peachpuff" : "lightblue"
  const thing = configuration.thing
  const aliases: string[] | undefined = thingsTypeGuards.isStringArray(thing.aliases) ? thing.aliases : t(thing.aliases)
  return {
    data: {
      ...thing,
      name: t(thing.name),
      shortName: t(thing.shortName || ""),
      aliases: aliases?.map(alias => t(alias)),
      description: thing.description !== undefined ? t(thing.description) : undefined
    },
    properties: {
      color: color,
      depth: configuration.depth,
      radius: calculateCircleRadius(configuration.depth),
      extraSpace: 0
    },
    children: configuration.children || []
  }

  function calculateCircleRadius(depth: number): number {
    const max = 20
    const min = 10
    const current = max - 5 * depth
    return current < min ? min : current
  }
}
