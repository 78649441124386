import React from "react";
import {Section} from "../Section";
import {introduction} from "../../data/Introduction";
import {replaceParagraphsWithPTags, replaceSquareBracketsWithSpanTags} from "../../textToHtmlSubstitution";
import * as styles from "./Einleitung.module.scss"
import {PersonType} from "../../model/PersonType";
import {reshapeTheTextAsNeeded} from "../../GrammarTranslater";
import {t, toId} from "../../i18n";
import {Language} from "../../model/LanguageBundle";

export const Introduction = ({personType, language}: {personType: PersonType, language: Language}) => {
  const title = t(introduction.header, language)
  const sectionId = toId(title)
  const html = replaceSquareBracketsWithSpanTags(replaceParagraphsWithPTags(reshapeTheTextAsNeeded(t(introduction.content, language), personType)))

  return (
    <Section id={sectionId} title={title} navigation avoidPageBreak language={language}>
      <div className={`alternative ${styles.content}`} dangerouslySetInnerHTML={{__html: html}}/>
    </Section>
  )
}
