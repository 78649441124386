export const professionalDevelopments = {
  year: {
    de: "Jahr",
    en: "Year"
  },
  type: {
    de: "Art",
    en: "Type"
  },
  organizer: {
    de: "Veranstalter",
    en: "Organizer"
  }
}
