import React, {FunctionComponent} from "react";
import {ITreeRepresentationNode} from "../model/TreeGraph";

export const UnorderedTreeList: FunctionComponent<{ node: ITreeRepresentationNode }> = ({node}) => {
  const getAliases = (aliases?: string[]) => {
    if (!aliases || aliases.length === 0) return ""
    return ` (${aliases.join(', ')})`
  }

  return (
    <ul>
      {
        node.children.map((child, idx) => {
          return (
            <li key={idx}>
              {child.data.name}
              <span className="aliases">{getAliases(child.data.aliases)}</span>
              {child.children.length > 0 && <UnorderedTreeList key={idx} node={child}/>}
            </li>
          )
        })
      }
    </ul>
  )
}
